import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useApprovedContent } from '../hooks'
import {
  BB_ADMIN,
  BB_DOCTOR,
  BB_PATIENT_INVOICE_FORM,
} from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import Invoices from '../Invoices'
import RestrictedButton from '../RestrictedButton'

const Registration: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_DOCTOR])

  return (
    <>
      {display && (
        <>
          <BannerGeneric
            title="Invoice Patients"
            description="Initiate Payment"
          >
            {
              <ul className="actions">
                <li>
                  <Link to={`/app/dashboard`} className="button special">
                    Back to Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/app/cases`}
                    className="button"
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </Link>
                </li>
                <li>
                  <RestrictedButton
                    text="Upload Payment Invoice"
                    roles={[BB_ADMIN]}
                    linkTo={BB_PATIENT_INVOICE_FORM}
                    target={true}
                  />
                </li>
              </ul>
            }
          </BannerGeneric>
          <section id="" className="submitform">
            <div className="inner">
              <div className="content">
                <section id="login">
                  <Invoices />
                </section>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Registration
