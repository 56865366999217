import { v4 as uuidv4 } from 'uuid'
import { handleWebHook } from '..'
import { setOrUpdateRTdb } from '../../services/firebase'
import { BB_STATUS_PAYMENT_COMPLETE } from '../constants'

// REF - https://developers.google.com/pay/india/api/web/create-payment-method

// Global key for canMakepayment cache.
const canMakePaymentCache = 'canMakePaymentCache'

/**
 * Check whether can make payment with Google Pay or not. It will check session storage
 * cache first and use the cache directly if it exists. Otherwise, it will call
 * canMakePayment method from PaymentRequest object and return the result, the
 * result will also be stored in the session storage cache for future usage.
 *
 * @private
 * @param {PaymentRequest} request The payment request object.
 * @return {Promise} a promise containing the result of whether can make payment.
 */
function checkCanMakePayment(request: any) {
  // Check canMakePayment cache, use cache result directly if it exists.
  if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
    return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]))
  }

  // If canMakePayment() isn't available, default to assume the method is
  // supported.
  var canMakePaymentPromise = Promise.resolve(true)

  // Feature detect canMakePayment().
  if (request.canMakePayment) {
    canMakePaymentPromise = request.canMakePayment()
  }

  return canMakePaymentPromise
    .then(result => {
      // Store the result in cache for future usage.
      sessionStorage[canMakePaymentCache] = result
      return result
    })
    .catch(err => {
      console.log('Error calling canMakePayment: ' + err)
    })
}

const sendWebhook = (text: string) => {
  handleWebHook({
    text,
    username: `Payer`,
  })
}

/** Launches payment request flow when user taps on buy button. */
export function onBuyClicked({
  url,
  amount,
  data,
}: {
  url: string
  amount: number
  data: any
}) {
  console.log({ url, amount, data })
  if (!window.PaymentRequest) {
    console.log('Web payments are not supported in this browser.')
    return
  }

  sendWebhook(`attempting for making payment of Rs. ${amount}...`)

  // Create supported payment method.
  const supportedInstruments = [
    {
      supportedMethods: ['https://tez.google.com/pay'],
      data: {
        pa: 'bloomb.co.in@sbi',
        pn: 'The Bloom Bunch Consulting',
        tr: uuidv4(), // Your custom transaction reference ID
        url,
        mc: '1234', //Your merchant category code
        tn: 'bloomB Consultation & Medicines Charge',
      },
    },
  ]

  // Create order detail data.
  const details = {
    total: {
      label: 'Total',
      amount: {
        currency: 'INR',
        value: amount.toString(), // sample amount
      },
    },
    displayItems: [
      {
        label: 'Original Amount',
        amount: {
          currency: 'INR',
          value: amount.toString(),
        },
      },
    ],
  }

  // Create payment request object.
  let request: any = null
  try {
    request = new PaymentRequest(supportedInstruments, details)
  } catch (e) {
    console.log('Payment Request Error: ' + e.message)
    return
  }
  if (!request) {
    sendWebhook(`Web payments are not supported in this browser.`)
    console.log('Web payments are not supported in this browser.')
    return
  }

  var canMakePaymentPromise = checkCanMakePayment(request)
  canMakePaymentPromise
    .then(result => {
      showPaymentUI(request, result, data)
    })
    .catch(err => {
      console.log('Error calling checkCanMakePayment: ' + err)
    })
}

/**
 * Show the payment request UI.
 *
 * @private
 * @param {PaymentRequest} request The payment request object.
 * @param {Promise} canMakePayment The promise for whether can make payment.
 */
function showPaymentUI(request: any, canMakePayment: any, data: any) {
  if (!canMakePayment) {
    handleNotReadyToPay()
    return
  }

  // Set payment timeout.
  let paymentTimeout = window.setTimeout(function() {
    window.clearTimeout(paymentTimeout)
    request
      .abort()
      .then(function() {
        sendWebhook(`Payment timed out after 20 minutes.`)
        console.log('Payment timed out after 20 minutes.')
      })
      .catch(function() {
        sendWebhook(`Unable to abort, user is in the process of paying.`)
        console.log('Unable to abort, user is in the process of paying.')
      })
  }, 20 * 60 * 1000) /* 20 minutes */

  request
    .show()
    .then(function(instrument: any) {
      window.clearTimeout(paymentTimeout)
      processResponse(instrument, data) // Handle response from browser.
    })
    .catch(function(err: any) {
      console.log(err)
    })
}

/**
 * Process the response from browser.
 *
 * @private
 * @param {PaymentResponse} instrument The payment instrument that was authed.
 */
async function processResponse(instrument: any, data: any) {
  // var instrumentString = instrumentToJsonString(instrument);
  var instrumentString = JSON.parse(instrument)
  console.log(instrumentString)
  // fetch('/buy', {
  //   method: 'POST',
  //   headers: new Headers({'Content-Type': 'application/json'}),
  //   body: instrumentString,
  // })
  const referenceKey = data.key
  delete data['key']
  try {
    const buyResultJson = await setOrUpdateRTdb({
      ref: `payments/${referenceKey}`,
      data: {
        ...data,
        paymentReference: JSON.stringify(instrumentString),
      },
      executeHook: {
        current: BB_STATUS_PAYMENT_COMPLETE,
      },
    })
    sendWebhook(`Payment reference successfully updated`)
    console.log('Successfull !', buyResultJson)
    // completePayment(instrument, buyResultJson?.status, buyResultJson?.message);
    completePayment(instrument, 'OK', 'Successful')
  } catch (err) {
    console.log('Error sending instrument to server.')
    sendWebhook(`Unable to process payment. ${err}.`)
    console.log('Unable to process payment. ' + err)
  }

  // .then(function(buyResult) {
  //   if (buyResult.ok) {
  //     return buyResult.json();
  //   }
  //   console.log('Error sending instrument to server.');
  // })
  // .then(function(buyResultJson) {
  //   completePayment(instrument, buyResultJson.status, buyResultJson.message);

  // })
  // .catch(function(err) {
  //   console.log('Unable to process payment. ' + err);
  // });
}

/**
 * Notify browser that the instrument authorization has completed.
 *
 * @private
 * @param {PaymentResponse} instrument The payment instrument that was authed.
 * @param {string} result Whether the auth was successful. Should be either
 * 'success' or 'fail'.
 * @param {string} msg The message to log in console.
 */
function completePayment(instrument: any, result: any, msg: any) {
  instrument
    .complete(result)
    .then(function() {
      sendWebhook(`Congratulations! You received a payment!`)
      console.log('Payment succeeds.')
      console.log(msg)
    })
    .catch(function(err: any) {
      console.log(err)
    })
}

/** Handle Google Pay not ready to pay case. */
function handleNotReadyToPay() {
  sendWebhook(
    `I'm either on a non supported phone or I do not have G pay installed`
  )
  alert('Google Pay is not ready to pay.')
}

/* Converts the payment response into a JSON string.
 *
 * @private
 * @param {PaymentResponse} paymentResponse The payment response to convert.
 * @return {string} The string representation of the payment response.
 */
function paymentResponseToJsonString(paymentResponse: any) {
  // PaymentResponse is an interface, JSON.stringify works only on dictionaries.
  var paymentResponseDictionary = {
    methodName: paymentResponse.methodName,
    details: paymentResponse.details,
    //   shippingAddress: addressToJsonString(paymentResponse.shippingAddress),
    shippingAddress: JSON.parse(paymentResponse.shippingAddress),
    shippingOption: paymentResponse.shippingOption,
    payerName: paymentResponse.payerName,
    payerPhone: paymentResponse.payerPhone,
    payerEmail: paymentResponse.payerEmail,
  }
  return JSON.stringify(paymentResponseDictionary, undefined, 2)
}
