import React, { useState } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useApprovedContent } from '../hooks'
import { BB_DOCTOR, BB_QUICK_REFERENCES_FORM } from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import References from '../References'
import RestrictedButton from '../RestrictedButton'
import Search from '../Search'

const Registration: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_DOCTOR])
  const [searchText, setSearchText] = useState('')

  return (
    <>
      {display && (
        <>
          <BannerGeneric title="Quick References" description="For Doctors">
            {
              <ul className="actions">
                <li>
                  <button
                    onClick={() => history.back()}
                    className="button special"
                  >
                    Back
                  </button>
                </li>
                <li>
                  <Link
                    to={`/app/cases`}
                    className="button"
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </Link>
                </li>
                <li>
                  <RestrictedButton
                    text="Add References"
                    roles={[BB_DOCTOR]}
                    linkTo={BB_QUICK_REFERENCES_FORM}
                    target={true}
                  />
                </li>
              </ul>
            }
          </BannerGeneric>
          <section
            id=""
            className="submitform"
            style={{ backgroundColor: '#f5f5f5' }}
          >
            <div className="inner">
              <div className="content">
                <section
                  style={{
                    padding: '10px',
                    // backgroundColor: '#fff',
                    borderRadius: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <Search
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </section>
                <section id="login">
                  <References text={searchText.toLowerCase()} />
                </section>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Registration
