import { useMachine } from '@xstate/react'
import React from 'react'
import {
  BB_STATUS_CONSULTATION_COMPLETE,
  BB_STATUS_MEDONLY_REQUEST_INITIATED,
} from '../utils/constants'
import { recordMachine } from './stateMachine/record'
import WorkflowTimeline from './WorkflowTimeline'

interface ConsultationUpdateProps {
  data: any
  current: number
}
const ConsultationUpdate: React.FC<ConsultationUpdateProps> = ({
  data,
  current: currentStatus,
}) => {
  /*const [
    caseHistorySnapshot,
    isCaseHistorysLoading,
    caseHistorysError,
  ] = useList(firebase.database().ref(`casehistory`))
  const [multiLevelData] = useMultiLevelFBRTRef(caseHistorySnapshot)
  const [filteredData] = useMultiLevelFilter({
    data: multiLevelData,
    dataFilter: [
      {
        key: 'Request ID',
        value: 'bBAPP42270',
      },
    ],
  })
  console.log('FOR PHARMA', filteredData)*/

  const [current, send] = useMachine(recordMachine, {
    context: {
      data,
    },
  })
  console.log('STATUS MACHINE', current)
  let actionNext: string
  if (currentStatus === BB_STATUS_CONSULTATION_COMPLETE) {
    actionNext = 'RECORDED'
  } else if (currentStatus === BB_STATUS_MEDONLY_REQUEST_INITIATED) {
    actionNext = 'RECORDED'
  }
  return (
    <>
      <WorkflowTimeline
        requestId={data.key}
        current={current}
        stage="record"
        data={[
          {
            button: actionNext,
            text: 'Click to update if Case History is recorded',
            action: () => send(actionNext),
          },
        ]}
      />
    </>
  )
}

export default ConsultationUpdate
