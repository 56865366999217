import React from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

interface Column {
  id: 'request' | 'status' | 'amount'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

// const columns: Column[] = [
//   { id: 'request', label: 'Request ID', minWidth: 170 },
//   { id: 'status', label: 'Status', minWidth: 100 },
//   {
//     id: 'amount',
//     label: 'Amount',
//     minWidth: 170,
//     align: 'right',
//     format: (value: number) => value.toFixed(2),
//   },
// ]

interface Data {
  request: string
  status: string

  amount: number
}

// function createData(request: string, status: string): Data {
//   const amount = 100
//   return { request, status, amount }
// }

// const rows = [
//   createData('India', 'IN'),
//   createData('China', 'CN'),
//   createData('Italy', 'IT'),
//   createData('United States', 'US'),
//   createData('Canada', 'CA'),
//   createData('Australia', 'AU'),
//   createData('Germany', 'DE'),
//   createData('Ireland', 'IE'),
//   createData('Mexico', 'MX'),
//   createData('Japan', 'JP'),
//   createData('France', 'FR'),
//   createData('United Kingdom', 'GB'),
//   createData('Russia', 'RU'),
//   createData('Nigeria', 'NG'),
//   createData('Brazil', 'BR'),
// ]

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      //   backgroundColor: theme.palette.common.black,
      backgroundColor: '#242943',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
})

export default function StickyHeadTable({
  columns,
  rows,
}: {
  columns: Column[]
  rows: Data[]
}) {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.request}
                  >
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          //   style={{backgroundColor:'red'}}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
