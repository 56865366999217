import { Link } from 'gatsby'
import React from 'react'
import { BB_HR } from '../utils/constants'
import { useApprovedContent } from './hooks'

const HROnly = () => {
  const [display] = useApprovedContent([BB_HR])

  return (
    <>
      {display && (
        <section id="two">
          <div className="inner">
            <header className="major">
              <h2>HR Only</h2>
            </header>
            <p>
              Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
              libero. Mauris aliquet magna magna sed nunc rhoncus pharetra.
              Pellentesque condimentum sem. In efficitur ligula tate urna.
              Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor.
              Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
              libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra
              et feugiat tempus.
            </p>
            <ul className="actions">
              <li>
                <Link to="/landing" className="button next">
                  Get Started
                </Link>
              </li>
            </ul>
          </div>
        </section>
      )}
    </>
  )
}

export default HROnly
