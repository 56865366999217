import { Link, RouteComponentProps } from '@reach/router'
import React from 'react'
import { Seo } from '../Seo'
import BannerGeneric from '../BannerGeneric'
import PrescriptionView from '../Prescription'
import RestrictedButton from '../RestrictedButton'
import {
  BB_CLINIC_PRESCRIPTION_FORM,
  BB_PHARMACIST,
} from '../../utils/constants'
import { useSlack } from '../hooks'

const Prescription: React.FC<RouteComponentProps> = ({ location }) => {
  console.log(location)
  useSlack(
    `Prescription of Patient : ${location?.state.googleData['Patient ID']}`
  )

  return (
    <>
      <Seo title="Prescription" />
      <BannerGeneric
        title="Generate Prescription"
        description="This is where you can screenshot a prescription"
      >
        <ul className="actions">
          <li>
            <Link to={`/app/cases`} className="button special">
              Back to Cases
            </Link>
          </li>
          <li>
            <RestrictedButton
              text="Upload Clinic Prescription"
              roles={[BB_PHARMACIST]}
              linkTo={BB_CLINIC_PRESCRIPTION_FORM}
              target={true}
            />
          </li>
        </ul>
      </BannerGeneric>
      <PrescriptionView data={location.state} />
    </>
  )
}

export default Prescription
