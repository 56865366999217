import { useMachine } from '@xstate/react'
import React from 'react'
import { consultationMachine } from './stateMachine/consultation'
import WorkflowTimeline from './WorkflowTimeline'

interface ConsultationUpdateProps {
  data: any
}
const ConsultationUpdate: React.FC<ConsultationUpdateProps> = ({ data }) => {
  const [current, send] = useMachine(consultationMachine, {
    context: {
      data,
    },
  })
  console.log('SHABS', data)

  console.log('STATUS MACHINE', current)
  return (
    <>
      <WorkflowTimeline
        requestId={data.key}
        current={current}
        stage="consultation"
        data={[
          {
            button: 'COMPLETE',
            text: 'Click to update Consultation Complete Status',
            action: () => send('COMPLETE'),
          },
          {
            button: 'NOSHOW',
            text: 'Click to update No Show Status',
            action: () => send('NOSHOW'),
          },
        ]}
      />
    </>
  )
}

export default ConsultationUpdate
