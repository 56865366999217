import React, { useContext, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { bloomBRegistration } from '../../services/firebase'
import { UserDispatchContext } from '../context/GlobalContextProvider'
import { useMessage } from '../hooks'
import {
  BB_DOCTOR,
  BB_HR,
  BB_PATIENT,
  BB_PHARMACIST,
} from '../../utils/constants'
import { handleWebHook } from '../../utils'

const Register: React.FC<RouteComponentProps> = () => {
  const dispatch = useContext<any>(UserDispatchContext)

  const firstName = useRef<any | null>(null)
  const lastName = useRef<any | null>(null)
  const emailAddress = useRef<any | null>(null)
  const password = useRef<any | null>(null)
  const photoURL = useRef<any | null>(null)
  const profileType = useRef<any | null>(null)

  const initialState = { error: false }
  const [msg, setMsg] = useMessage()

  // const [message, setMessage] = useState({ type: '', text: '' })

  const handleChange = (e: any) => {}

  const handleSubmit = (e: any) => {
    e.preventDefault()
    console.log('handleSubmit  action in progress!.....')
    bloomBRegistration({
      emailAddress: emailAddress.current.value,
      password: password.current.value,
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      photoURL: photoURL.current.value,
      profile: profileType.current.value,
    })
      .then((res: any) => {
        console.log('REGISTER', res)
        handleWebHook({
          text: `Someone did sign up in the website...`,
        })
        dispatch({
          type: 'MESSAGE',
          payload: {
            type: 'success',
            text: 'Successfully Registered! Please login',
          },
        })
        navigate('/app/login')
      })

      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // ...
        console.error(errorCode, errorMessage)
        setMsg({ type: 'error', text: errorMessage })
      })
  }

  return (
    <section id="" className="submitform">
      <div className="inner">
        <header className="major">
          <h1>Sign Up</h1>
        </header>
        <div className="content">
          <section id="login">
            <div className="inner">
              <section>
                <form onSubmit={handleSubmit}>
                  <div className="field half first">
                    <label htmlFor="first-name">First Name</label>
                    <input
                      ref={firstName}
                      type="text"
                      name="first-name"
                      id="first-name"
                      required
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="field half">
                    <label htmlFor="last-name">Last Name</label>
                    <input
                      ref={lastName}
                      type="text"
                      name="last-name"
                      id="last-name"
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="field half first">
                    <label htmlFor="email-address">Email</label>
                    <input
                      ref={emailAddress}
                      type="email"
                      name="email-address"
                      id="email-address"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field half">
                    <label htmlFor="pass-word">Password</label>
                    <input
                      ref={password}
                      type="password"
                      name="pass-word"
                      id="pass-word"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field half first">
                    <label htmlFor="profile-type">Profile</label>
                    <select
                      name="profile-type"
                      id="profile-type"
                      required
                      ref={profileType}
                    >
                      <option value=""></option>
                      <option value={BB_DOCTOR}>Doctor</option>
                      <option value={BB_PHARMACIST}>Pharmacist</option>
                      <option value={BB_HR}>HR</option>
                      <option value={BB_PATIENT}>Patient</option>
                    </select>
                  </div>
                  <div className="field half">
                    <label htmlFor="photo-url">Photo URL</label>
                    <input
                      ref={photoURL}
                      type="text"
                      name="photo-url"
                      id="photo-url"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Sign Up"
                        className="special"
                      />
                    </li>
                    <li>
                      <input type="reset" value="Clear" />
                    </li>
                    <li>
                      {msg && msg.text && (
                        <Message type={msg.type} message={msg.text} />
                      )}
                    </li>
                  </ul>
                </form>
                <div className="field half first">
                  <Link to="/app/login" className="">
                    <i>Already have an account? Sign In</i>
                  </Link>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Register
