import { createMachine } from 'xstate'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'request'; context: undefined }
  | { value: 'appointment'; context: undefined }
  | { value: 'consultation'; context: undefined }

const logMsg = () => console.log('Creating & Requesting...')

export const workflowMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'workflows',
  initial: 'idle',
  states: {
    idle: {
      on: {
        NEXT: 'request',
      },
    },
    request: {
      invoke: {
        id: 'createRequest',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting...', context)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      on: {
        NEXT: 'consultation',
      },
    },
    appointment: {
      on: {
        NEXT: 'consultation',
      },
    },
    consultation: {
      type: 'final',
    },
  },
})
