import { useMachine } from '@xstate/react'
import React from 'react'
import {
  BB_STATUS_FEEDBACK_REQUESTED,
  BB_STATUS_PAYMENT_COMPLETE,
} from '../utils/constants'
import { feedbackMachine } from './stateMachine/feedback'
import WorkflowTimeline from './WorkflowTimeline'

interface ConsultationUpdateProps {
  data: any
  current: number
}
const ConsultationUpdate: React.FC<ConsultationUpdateProps> = ({
  data,
  current: currentStatus,
}) => {
  const [current, send] = useMachine(feedbackMachine, {
    context: {
      data,
    },
  })
  console.log('STATUS MACHINE', current)
  let actionNext: string
  if (currentStatus === BB_STATUS_PAYMENT_COMPLETE) {
    actionNext = 'REQUESTED'
  } else if (currentStatus === BB_STATUS_FEEDBACK_REQUESTED) {
    actionNext = 'RECEIVED'
  } else {
    actionNext = 'CLOSED'
  }
  return (
    <>
      <WorkflowTimeline
        requestId={data.key}
        current={current}
        stage="feedback"
        data={[
          {
            button: actionNext,
            text: 'Click to update',
            action: () => send(actionNext),
          },
        ]}
      />
    </>
  )
}

export default ConsultationUpdate
