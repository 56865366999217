import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from '@reach/router'

import { useList } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { useLoggedInUser, useSlack } from '../hooks'
import BannerGeneric from '../BannerGeneric'
import Earnings from '../Earnings'
import AlertMsg from '../AlertMsg'

interface Column {
  id: 'request' | 'status' | 'amount'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}
interface Data {
  request: string
  status: string

  amount: number
}
const Registration: React.FC<RouteComponentProps> = () => {
  const [loggedInUsername] = useLoggedInUser()
  useSlack('Earnings')

  // const [patientsSnapshot, isPatientsLoading, patientsError] = useList(
  //   firebase.database().ref(`patients`)
  // )
  // const columns = [
  //   'name',
  //   'id',
  //   'dob',
  //   'email',
  //   'mobile',
  //   'address',
  //   'initialComplaint',
  //   'updatedBy',
  //   BB_REGISTERED_DATE,
  // ]
  // const [datatableData] = useSnapShotForTable({
  //   data: patientsSnapshot,
  //   columns,
  // })

  // const datatableColumns = [
  //   'Name',
  //   'Patient ID',
  //   'Dob',
  //   'Email',
  //   'Mobile',
  //   'Address',
  //   'Initial Complaint',
  //   'Updated By',
  //   BB_REGISTERED_DATE,
  // ]
  // const [patientsData, setPatientsData] = useState<string[][]>([])
  // useEffect(() => {
  //   if (patientsSnapshot?.length && !isPatientsLoading) {
  //     let tableData: string[][] = []
  //     patientsSnapshot?.map((item: any) => {
  //       console.log('Key', item.key)
  //       console.log('Value', item.val().name)
  //       const dt = [
  //         item.val().name,
  //         item.val().id,
  //         item.val().email,
  //         item.val().mobile,
  //       ]
  //       tableData.push(dt)
  //       setPatientsData(tableData)
  //     })
  //   }
  // }, [patientsSnapshot, isPatientsLoading])

  const [earningsSnapshot] = useList(
    firebase.database().ref(`earnings/${loggedInUsername}`)
  )
  console.log('EARN', earningsSnapshot)

  // const columns = [
  //   'requestId',
  //   'status',
  //   'amount',
  //   // BB_REGISTERED_DATE,
  // ]

  const columns = [
    { id: 'requestId', label: 'Request ID', minWidth: 70 },
    { id: 'status', label: 'Status', minWidth: 70 },
    {
      id: 'amount',
      label: 'Amount',
      minWidth: 100,
      align: 'right',
      format: (value: number) => value.toFixed(2),
    },
  ]

  // const [datatableData] = useSnapShotForTable({
  //   data: earningsSnapshot,
  //   columns,
  // })

  const [datatableData, setDatatableData] = useState<string[][]>([])
  console.log('EARN TABLE', datatableData)

  useEffect(() => {
    if (earningsSnapshot?.length) {
      console.log('REQQQ', earningsSnapshot?.length)
      let tableData: any[] = []
      earningsSnapshot?.map((item: any) => {
        console.log('REQQQKey', item.key)
        console.log('REQQQValue', item.val())
        tableData.push(item.val())
      })
      setDatatableData(tableData)
    }
  }, [earningsSnapshot])

  console.log('EARN', datatableData)
  const options = {
    filterType: 'checkbox',
  }

  return (
    <>
      <BannerGeneric
        title="Patient Records"
        description="This is where patient registrations are stored"
      >
        {
          <ul className="actions">
            <li>
              <Link to={`/app/dashboard`} className="button special">
                Back to Dashboard
              </Link>
            </li>
          </ul>
        }
      </BannerGeneric>
      <section
        id=""
        className="submitform"
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <div className="inner">
          <div className="content">
            <section id="login">
              <Earnings
                username={loggedInUsername}
                columns={columns}
                rows={datatableData}
              />
              <br />
              <AlertMsg
                type="info"
                text="Earnings if any, will be paid out every month end"
              />
              {/* {datatableData && (
                // <Datatable
                //   title={''}
                //   data={datatableData}
                //   columns={datatableColumns}
                // />
              )} */}
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Registration
