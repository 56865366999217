import React, { useContext, useRef, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { getUser, setOrUpdateRTdb } from '../../services/firebase'
import { UserDispatchContext } from '../context/GlobalContextProvider'
import {
  useApprovedContent,
  useBitly,
  useGoogleSheet,
  useMessage,
  useSingleLevelFBRTRef,
} from '../hooks'
import firebase from 'gatsby-plugin-firebase'
import {
  generateProfileId,
  getBitlyUrl,
  getFutureDateAfter,
  getTimestamp,
  getUrlParam,
  getUsername,
  handleWebHook,
  injectUid,
  showNotification,
} from '../../utils'
import { useForm } from 'react-hook-form'
import {
  BB_ACCESS_CODE_SECRET,
  BB_ADMIN,
  BB_BASE_URL,
  BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID,
  BB_DOCUMENT_DOWNLOAD_LINK_GENERATED,
  BB_STATUS_PAYMENT_LINK_GENERATED,
} from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import { useList, useListVals } from 'react-firebase-hooks/database'
import ShowModal from '../ShowModal'
import CopyText from '../CopyText'
import { v4 as uuidv4 } from 'uuid'
import { useSnackbar } from 'notistack'
// import PrescriptionView from '../Prescription'
import RestrictedButton from '../RestrictedButton'
import AlertMsg from '../AlertMsg'

const Registration: React.FC<RouteComponentProps> = ({}) => {
  const [bitlink, bBitly] = useBitly(BB_BASE_URL)

  const [googleData] = useGoogleSheet(BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID)
  console.log('Prescriptions', googleData)
  const { enqueueSnackbar } = useSnackbar()

  const [display] = useApprovedContent([BB_ADMIN])

  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )

  const [queueSnapshot, isQueuesLoading, queuesError] = useList(
    firebase.database().ref(`queue`)
  )
  // console.log('UUUUU', queueSnapshot)

  const [queueData] = useSingleLevelFBRTRef(queueSnapshot)

  const user = getUser()
  const dispatch = useContext<any>(UserDispatchContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [msg, setMsg] = useMessage()

  const [accessLink, setAccessLink] = useState({
    code: '',
    url: '',
  })

  const patientIDRef = useRef()
  const consultationRef = useRef()
  const carrierRef = useRef()
  const accessLinkRef = useRef()

  const onSubmit = async (data: any) => {
    console.log('TOMO', getFutureDateAfter(10))
    try {
      console.log('handleSubmit  action in progress!.....', data)
      const loggedInUsername = getUsername(user.accessProfile.username)
      const codeGenerated = accessLink.code
      console.log('SUBMIT', data)
      delete data['patientID']
      await setOrUpdateRTdb({
        ref: `downloads/${patientIDRef.current.value}/${getTimestamp()}`,
        data: {
          ...data,
          accessCode: codeGenerated,
          updatedBy: loggedInUsername,
        },
      })

      await setOrUpdateRTdb({
        ref: `specialAccess/${getTimestamp()}`,
        data: {
          accessCode: codeGenerated,
          expiry: getFutureDateAfter(10),
          updatedBy: loggedInUsername,
        },
      })

      showNotification({
        enqueueSnackbar,
        text:
          'Download Link is now available for the Patient! Link can now be shared.',
        type: 'success',
      })

      handleWebHook({
        text: `just generated download link for Patient ${patientIDRef.current.value} & the link is ${accessLink.url}`,
      })

      // Filter for above patient id's pending medicine-only request
      // const filteredData = multilevelData.filter(userlevel => {
      //   return userlevel.filter(
      //     item =>
      //       item[1]['requestType'] === BB_MEDICINE_ONLY_REQUEST &&
      //       item[1]['status'] === BB_PENDING_STATUS &&
      //       item[1]['patientId'] === data.patientId
      //   )
      //

      // dispatch({
      //   type: 'MESSAGE',
      //   payload: {
      //     type: 'success',
      //     text: 'Payment Request Successful!',
      //   },
      // })
      navigate('/app/dashboard')
    } catch (err) {
      setMsg({ type: 'error', text: err.message })
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }

  const handleAccessLink = async () => {
    console.log('ACCESSSSS 1', patientIDRef.current.value)
    const codeGenerated = await generateProfileId({
      data: bloomBUidsSnapshot,
      profile: BB_ACCESS_CODE_SECRET,
    })
    const uuid = injectUid(uuidv4(), codeGenerated)
    const bitlyPyLink = await getBitlyUrl(
      `${BB_BASE_URL}/app/downloaddoc/${uuid}/${patientIDRef.current.value}`,
      bBitly
    )
    accessLinkRef.current.value = bitlyPyLink
    setAccessLink({ code: codeGenerated, url: accessLinkRef.current.value })
  }

  return (
    <>
      {display && (
        <>
          <BannerGeneric
            title="Release Patient Document"
            description="This is for generating download link for patients"
          >
            {
              <ul className="actions">
                <li>
                  <Link to={`/app/dashboard`} className="button special">
                    Back to Dashboard
                  </Link>
                </li>
              </ul>
            }
          </BannerGeneric>
          <section id="" className="submitform">
            <div className="inner">
              {/* <header className="major">
                <h1>Medicine-Only Request</h1>
              </header> */}
              <div className="content">
                <section id="login">
                  <div className="inner">
                    <section>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <AlertMsg
                          type="warning"
                          text="Always enter Patient ID before you generate access
                          link. Also manually enter a character in accesslink before you submit"
                        />
                        <br />
                        <div className="field half first">
                          <label htmlFor="name">Patient ID</label>
                          <input
                            type="text"
                            {...register('patientID', { required: true })}
                            autoComplete="off"
                            placeholder="bb12345"
                            ref={patientIDRef}
                          />
                          <br />
                          <div style={{ position: 'relative' }}>
                            <input
                              type="text"
                              {...register('accessLink', { required: true })}
                              autoComplete="off"
                              placeholder="Access link will appear here"
                              ref={accessLinkRef}
                            />
                            <CopyText
                              text={accessLink?.url}
                              styles={{
                                position: 'absolute',
                                right: '0',
                                top: '5px',
                              }}
                            />
                          </div>
                          <br />
                          <input
                            type="button"
                            value="Generate Access Link"
                            className=""
                            onClick={handleAccessLink}
                          />
                        </div>
                        <div className="field half">
                          <label htmlFor="initialComplaint">Documents</label>
                          <textarea
                            {...register('documents', {
                              required: true,
                            })}
                            placeholder="Enter your message"
                            rows={7}
                          ></textarea>
                        </div>
                        <ul className="actions">
                          <li>
                            <input
                              type="submit"
                              value="Submit"
                              className="special"
                            />
                          </li>
                          <li>
                            <input type="reset" value="Clear" />
                          </li>
                          <li>
                            {msg && msg.text && (
                              <Message type={msg.type} message={msg.text} />
                            )}
                          </li>
                        </ul>
                      </form>
                    </section>
                    {/* <RestrictedButton
                      text="Patient Invoice"
                      roles={[BB_ADMIN]}
                      linkTo={`/app/prescription`}
                      target={true}
                      state={{
                        googleData: {},
                        priceEditable: true,
                        deliveryEditable: true,
                        totalEditable: true,
                        amountEditable: true,
                        generateInvoice: true,
                      }}
                    /> */}
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Registration
