import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useInitLogin } from '../hooks'
import BannerGeneric from '../BannerGeneric'
import { Seo } from '../Seo'
import Timeline from '../Timeline'
import Tile from '../Tile'
import RestrictedButton from '../RestrictedButton'
import CompleteWorkflow from '../CompleteWorkflow'

const Dashboard: React.FC<RouteComponentProps> = () => {
  const [userData] = useInitLogin()
  // useEffect(() => {
  //   firebase
  //     .database()
  //     .ref(`workflowLevels`)
  //     .once('value', snapshot => {
  //       const workflowLevelsArray: any[] = []
  //       snapshot.forEach(item => {
  //         workflowLevelsArray.push({ code: item.key, description: item.val() })
  //       })
  //       const statusObject = workflowLevelsArray.filter(
  //         item => item.description === 'APPOINTMENT FIXED'
  //       )
  //       console.log('HOO', statusObject)
  //     })
  // }, [])
  return (
    <>
      <>
        <Seo title="Dashboard" />
        <BannerGeneric
          title={`Welcome ${userData?.displayName}`}
          description="This is your dashboard"
        >
          <ul className="actions">
            <li>
              <Link to={`/app/special`} className="button special">
                Special Privileges
              </Link>
            </li>
            <li>
              <Link
                to={`/app/dashboard`}
                className="button"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Link>
            </li>
            <li>
              <Link to={`/app/status`} className="button special">
                Check Status
              </Link>
            </li>
            <li>
              <CompleteWorkflow />
            </li>
          </ul>
        </BannerGeneric>
        <div id="main">
          <Timeline />
          <section id="one" className="tiles">
            {/* <Tile
              title="Requests"
              subtitle="Both Appointment/Medine-only ones"
              linkTo="/app/requests"
            /> */}
            <Tile
              title="Medicine-only Requests"
              subtitle="Requests without Appointments"
              linkTo="/app/medicineonly"
            />
            <Tile
              title="Appointments"
              subtitle="For All Listings"
              linkTo="/app/appointments"
            />
            {/* <DoctorOnly /> */}
            <Tile
              title="Registration"
              subtitle="For New Patients"
              linkTo="/app/registration"
            />
            <Tile
              title="Patients"
              subtitle="Search Records"
              linkTo="/app/patients"
            />
            {/* <AdminOnly /> */}
            {/* <PharmacistOnly /> */}
          </section>
          {/* <HROnly /> */}
        </div>
      </>
    </>
  )
}

export default Dashboard
