import { useMachine } from '@xstate/react'
import React from 'react'
import {
  BB_STATUS_PAYMENT_DEFERRED,
  BB_STATUS_PAYMENT_DUE_INFORMED,
  BB_STATUS_PAYMENT_LINK_GENERATED,
} from '../utils/constants'
import { paymentMachine } from './stateMachine/payment'
import WorkflowTimeline from './WorkflowTimeline'

interface ConsultationUpdateProps {
  data: any
  current: number
}
const ConsultationUpdate: React.FC<ConsultationUpdateProps> = ({
  data,
  current: currentStatus,
}) => {
  const [current, send] = useMachine(paymentMachine, {
    context: {
      data,
    },
  })
  console.log('STATUS MACHINE', current)
  let actionNext: string
  // const actionNext =
  //   currentStatus === BB_STATUS_PAYMENT_LINK_GENERATED ? 'INFORMED' : 'DEFFERED'

  if (currentStatus === BB_STATUS_PAYMENT_LINK_GENERATED) {
    actionNext = 'INFORMED'
  } else if (currentStatus === BB_STATUS_PAYMENT_DUE_INFORMED) {
    actionNext = 'DEFFERED'
  } else if (currentStatus === BB_STATUS_PAYMENT_DEFERRED) {
    actionNext = 'COMPLETE'
  }
  return (
    <>
      <WorkflowTimeline
        requestId={data.key}
        current={current}
        stage="payment"
        data={[
          {
            button: actionNext,
            text: 'Click to update if Payment due is informed',
            action: () => send(actionNext),
          },
        ]}
      />
    </>
  )
}

export default ConsultationUpdate
