import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { getUser, setOrUpdateRTdb, updateQueue } from '../../services/firebase'
import { UserDispatchContext } from '../context/GlobalContextProvider'
import { useApprovedContent, useMessage, useSingleLevelFBRTRef } from '../hooks'
import firebase from 'gatsby-plugin-firebase'
import {
  generateProfileId,
  getTimestamp,
  getUsername,
  showNotification,
} from '../../utils'
import { useForm } from 'react-hook-form'
import {
  BB_ADMIN,
  BB_DONE_STATUS,
  BB_MEDICINE_ONLY_REQUEST,
  BB_PENDING_STATUS,
  BB_REGISTERED_DATE,
  BB_STATUS_MEDONLY_REQUEST_INITIATED,
} from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import { useList, useListVals } from 'react-firebase-hooks/database'
import Requests from '../Requests'
import { useSnackbar } from 'notistack'

const Registration: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_ADMIN])
  const { enqueueSnackbar } = useSnackbar()

  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )

  const [queueSnapshot, isQueuesLoading, queuesError] = useList(
    firebase.database().ref(`queue`)
  )
  console.log('UUUUU', queueSnapshot)

  const [queueData] = useSingleLevelFBRTRef(queueSnapshot)

  // const [multilevelData, setMultilevelData] = useState<string[][]>([])
  /* multilevelData structure
    [{
    "username": [
      ["datetime", {
        "patientId": "bB12123123",
        "requestType": "MEDSONLYREQ",
        "status": "pending",
        "updatedBy": "hr"
      }],
      ["datetime2", {
        "patientId": "bB12332424",
        "requestType": "APPREQ",
        "status": "pending",
        "updatedBy": "hr"
      }]
    ]
  }, {
    "username2": [
      ["datetime3", {
        "patientId": "BB23424523",
        "requestType": "APPREQ",
        "status": "pending",
        "updatedBy": "hr"
      }]
    ]
  }]


  */
  /*useEffect(() => {
    if (queueSnapshot?.length) {
      let snapShotData: any[] = []
      queueSnapshot.map((item: any) => {
        console.log('QQQQ Key', item.key)
        console.log('QQQQ Val', item.val())
        const object = {
          [item.key]: Object.entries(item.val()),
        }
        snapShotData.push(object)
      })

      setMultilevelData(snapShotData)
      console.log('QQQQ FINAL', JSON.stringify(snapShotData))
    }
  }, [queueSnapshot])*/

  const user = getUser()
  const dispatch = useContext<any>(UserDispatchContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [msg, setMsg] = useMessage()
  const onSubmit = async (data: any) => {
    try {
      console.log('handleSubmit  action in progress!.....', data)
      const loggedInUsername = getUsername(user.accessProfile.username)
      const patientIdGenerated = await generateProfileId({
        data: bloomBUidsSnapshot,
        profile: BB_MEDICINE_ONLY_REQUEST,
      })
      await setOrUpdateRTdb({
        ref: `medicineOnlyRequests/${getTimestamp()}`,
        data: {
          ...data,
          id: patientIdGenerated,
          updatedBy: loggedInUsername,
        },
        executeHook: {
          current: BB_STATUS_MEDONLY_REQUEST_INITIATED,
        },
      })

      // Filter for above patient id's pending medicine-only request
      // const filteredData = multilevelData.filter(userlevel => {
      //   return userlevel.filter(
      //     item =>
      //       item[1]['requestType'] === BB_MEDICINE_ONLY_REQUEST &&
      //       item[1]['status'] === BB_PENDING_STATUS &&
      //       item[1]['patientId'] === data.patientId
      //   )
      //

      await updateQueue({
        data: queueData,
        id: data.patientId,
        type: BB_MEDICINE_ONLY_REQUEST,
        from: BB_PENDING_STATUS,
        to: BB_DONE_STATUS,
      })

      showNotification({
        enqueueSnackbar,
        text: 'Medicine-Only Request Successful!',
        type: 'success',
      })
      // dispatch({
      //   type: 'MESSAGE',
      //   payload: {
      //     type: 'success',
      //     text: 'Medicine-Only Request Successful!',
      //   },
      // })
      navigate('/app/medsrequest')
    } catch (err) {
      setMsg({ type: 'error', text: err.message })
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }

  return (
    <>
      {display && (
        <>
          <BannerGeneric
            title="Medicine-Only Request"
            description="This is an alternative option to skip appointments"
          >
            {
              <ul className="actions">
                <li>
                  <Link to={`/app/dashboard`} className="button special">
                    Back to Dashboard
                  </Link>
                </li>
              </ul>
            }
          </BannerGeneric>
          <section id="" className="submitform">
            <div className="inner">
              {/* <header className="major">
                <h1>Medicine-Only Request</h1>
              </header> */}
              <div className="content">
                <section id="login">
                  <div className="inner">
                    <section>
                      <Requests
                        filter={{
                          type: BB_MEDICINE_ONLY_REQUEST,
                          status: BB_PENDING_STATUS,
                        }}
                        columns={[
                          'patientId',
                          'name',
                          'requestType',
                          'status',
                          'updatedBy',
                          BB_REGISTERED_DATE,
                        ]}
                        table="queue"
                        tableColumns={[
                          'Patient ID',
                          'Patient Name',
                          'Request Type',
                          'Status',
                          'Updated By',
                          BB_REGISTERED_DATE,
                        ]}
                        title="Requests/Reminders"
                      />
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field half first">
                          <label htmlFor="name">Patient ID</label>
                          <input
                            type="text"
                            {...register('patientId', { required: true })}
                            autoComplete="off"
                          />
                          <br />
                          <label htmlFor="name">Patient Name</label>
                          <input
                            type="text"
                            {...register('name', { required: true })}
                            autoComplete="off"
                          />
                        </div>
                        <div className="field half">
                          <label htmlFor="initialComplaint">Problem</label>
                          <textarea
                            {...register('problem', {
                              required: true,
                            })}
                            placeholder="Enter your message"
                            rows={6}
                          ></textarea>
                        </div>
                        <ul className="actions">
                          <li>
                            <input
                              type="submit"
                              value="Submit"
                              className="special"
                            />
                          </li>
                          <li>
                            <input type="reset" value="Clear" />
                          </li>
                          <li>
                            {msg && msg.text && (
                              <Message type={msg.type} message={msg.text} />
                            )}
                          </li>
                        </ul>
                      </form>
                    </section>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Registration
