import React, { useContext, useRef, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { getUser, setOrUpdateRTdb } from '../../services/firebase'
import { UserDispatchContext } from '../context/GlobalContextProvider'
import {
  useApprovedContent,
  useBitly,
  useGoogleSheet,
  useMessage,
  useSingleLevelFBRTRef,
} from '../hooks'
import firebase from 'gatsby-plugin-firebase'
import {
  generateProfileId,
  getBitlyUrl,
  getFutureDateAfter,
  getTimestamp,
  getUrlParam,
  getUsername,
  handleWebHook,
  injectUid,
  showNotification,
} from '../../utils'
import { useForm } from 'react-hook-form'
import {
  BB_ACCESS_CODE_SECRET,
  BB_ADMIN,
  BB_BASE_URL,
  BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID,
  BB_STATUS_PAYMENT_LINK_GENERATED,
} from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import { useList, useListVals } from 'react-firebase-hooks/database'
import ShowModal from '../ShowModal'
import CopyText from '../CopyText'
import { v4 as uuidv4 } from 'uuid'
import { useSnackbar } from 'notistack'
// import PrescriptionView from '../Prescription'
import RestrictedButton from '../RestrictedButton'

const Registration: React.FC<RouteComponentProps> = ({ location }) => {
  const { data: medsData } = location?.state
  console.log('SHABS', medsData)
  const [bitlink, bBitly] = useBitly(BB_BASE_URL)

  const [googleData] = useGoogleSheet(BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID)
  console.log('Prescriptions', googleData)
  const { enqueueSnackbar } = useSnackbar()

  const imgUrl = getUrlParam(medsData['Clinic Prescription'], 'id')
  const [display] = useApprovedContent([BB_ADMIN])

  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )

  const [queueSnapshot, isQueuesLoading, queuesError] = useList(
    firebase.database().ref(`queue`)
  )
  // console.log('UUUUU', queueSnapshot)

  const [queueData] = useSingleLevelFBRTRef(queueSnapshot)

  const user = getUser()
  const dispatch = useContext<any>(UserDispatchContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [msg, setMsg] = useMessage()

  const [paymentLink, setPaymentLink] = useState({
    code: '',
    url: '',
  })

  const onSubmit = async (data: any) => {
    console.log('TOMO', getFutureDateAfter(10))
    try {
      console.log('handleSubmit  action in progress!.....', data)
      const loggedInUsername = getUsername(user.accessProfile.username)
      const codeGenerated = paymentLink.code
      console.log('SUBMIT', data)

      await setOrUpdateRTdb({
        ref: `payments/${medsData['Request ID']}`,
        data: {
          ...data,
          accessCode: codeGenerated,
          ruleId: 'bBRUL12345',
          type: 'Courier',
          updatedBy: loggedInUsername,
          id: medsData['Request ID'],
        },
        executeHook: {
          current: BB_STATUS_PAYMENT_LINK_GENERATED,
        },
      })

      await setOrUpdateRTdb({
        ref: `specialAccess/${getTimestamp()}`,
        data: {
          accessCode: codeGenerated,
          expiry: getFutureDateAfter(10),
          updatedBy: loggedInUsername,
        },
      })

      showNotification({
        enqueueSnackbar,
        text: 'Invoiced! Payment link can now be shared.',
        type: 'success',
      })

      handleWebHook({
        text: `just invoiced ${medsData['Request ID']} case & generated the payment link ${paymentLink.url}`,
      })

      // Filter for above patient id's pending medicine-only request
      // const filteredData = multilevelData.filter(userlevel => {
      //   return userlevel.filter(
      //     item =>
      //       item[1]['requestType'] === BB_MEDICINE_ONLY_REQUEST &&
      //       item[1]['status'] === BB_PENDING_STATUS &&
      //       item[1]['patientId'] === data.patientId
      //   )
      //

      // dispatch({
      //   type: 'MESSAGE',
      //   payload: {
      //     type: 'success',
      //     text: 'Payment Request Successful!',
      //   },
      // })
      navigate('/app/invoice')
    } catch (err) {
      setMsg({ type: 'error', text: err.message })
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }

  const netRef = useRef()
  const consultationRef = useRef()
  const carrierRef = useRef()
  const paymentLinkRef = useRef()
  const handleNetAmount = () => {
    const total =
      Number(medsData['Total Medicines Cost']) +
      Number(consultationRef?.current.value) +
      Number(carrierRef.current.value)
    netRef.current.value = total
  }

  const handlePaymentLink = async () => {
    const codeGenerated = await generateProfileId({
      data: bloomBUidsSnapshot,
      profile: BB_ACCESS_CODE_SECRET,
    })
    const uuid = injectUid(uuidv4(), codeGenerated)
    const bitlyPyLink = await getBitlyUrl(
      `${BB_BASE_URL}/app/payinvoice/${uuid}/${medsData['Request ID']}`,
      bBitly
    )
    paymentLinkRef.current.value = bitlyPyLink
    setPaymentLink({ code: codeGenerated, url: paymentLinkRef.current.value })
  }

  return (
    <>
      {display && (
        <>
          <BannerGeneric
            title="Create Payment"
            description="This is for initiating payment workflow"
          >
            {
              <ul className="actions">
                <li>
                  <Link to={`/app/dashboard`} className="button special">
                    Back to Dashboard
                  </Link>
                </li>
              </ul>
            }
          </BannerGeneric>
          <section id="" className="submitform">
            <div className="inner">
              {/* <header className="major">
                <h1>Medicine-Only Request</h1>
              </header> */}
              <div className="content">
                <section id="login">
                  <div className="inner">
                    <section>
                      <div className="table-wrapper">
                        <table className="alt">
                          <thead>
                            <tr>
                              <th>Pharmacy</th>
                              <th>Request ID</th>
                              <th>Total</th>
                              <th>Receipt</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {`${getUsername(
                                  medsData["Pharmacist's bloomB ID"]
                                )}'s`}
                              </td>
                              <td>{medsData['Request ID']}</td>
                              <td>Rs. {medsData['Total Medicines Cost']}</td>
                              <td>
                                <ShowModal
                                  buttonLabel="View"
                                  title={`${getUsername(
                                    medsData["Pharmacist's bloomB ID"]
                                  )}'s Prescription`}
                                >
                                  <img
                                    src={`https://drive.google.com/uc?export=view&id=${imgUrl}`}
                                    width="100%"
                                  />
                                </ShowModal>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field half first">
                          <label htmlFor="name">Consultation Fee</label>
                          <input
                            type="text"
                            {...register('consultationFee', { required: true })}
                            autoComplete="off"
                            ref={consultationRef}
                          />
                        </div>
                        <div className="field half">
                          <label htmlFor="name">Carrier charge if any</label>
                          <input
                            type="text"
                            {...register('carrierCharge', { required: true })}
                            autoComplete="off"
                            ref={carrierRef}
                          />
                        </div>
                        <div className="field half first">
                          <label htmlFor="name">Net Amount</label>
                          <input
                            type="text"
                            {...register('netAmount', { required: true })}
                            autoComplete="off"
                            placeholder="Click me to auto calculate"
                            onFocus={handleNetAmount}
                            ref={netRef}
                          />
                          <br />
                          <div style={{ position: 'relative' }}>
                            <input
                              type="text"
                              {...register('paymentLink', { required: true })}
                              autoComplete="off"
                              placeholder="Payment link will appear here"
                              ref={paymentLinkRef}
                            />
                            <CopyText
                              text={paymentLink?.url}
                              styles={{
                                position: 'absolute',
                                right: '0',
                                top: '5px',
                              }}
                            />
                          </div>
                          <br />
                          <input
                            type="button"
                            value="Generate Payment Link"
                            className=""
                            onClick={handlePaymentLink}
                          />
                        </div>
                        <div className="field half">
                          <label htmlFor="initialComplaint">Remarks</label>
                          <textarea
                            {...register('remarks', {
                              required: true,
                            })}
                            placeholder="Enter your message"
                            rows={7}
                          ></textarea>
                        </div>
                        <ul className="actions">
                          <li>
                            <input
                              type="submit"
                              value="Submit"
                              className="special"
                            />
                          </li>
                          <li>
                            <input type="reset" value="Clear" />
                          </li>
                          <li>
                            {msg && msg.text && (
                              <Message type={msg.type} message={msg.text} />
                            )}
                          </li>
                        </ul>
                      </form>
                    </section>
                    {/* <RestrictedButton
                      text="Patient Invoice"
                      roles={[BB_ADMIN]}
                      linkTo={`/app/prescription`}
                      target={true}
                      state={{
                        googleData: {},
                        priceEditable: true,
                        deliveryEditable: true,
                        totalEditable: true,
                        amountEditable: true,
                        generateInvoice: true,
                      }}
                    /> */}
                  </div>
                </section>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}

export default Registration
