import { RouteComponentProps } from '@reach/router'
import firebase from 'gatsby-plugin-firebase'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  useList,
  useListVals,
  useObjectVal,
} from 'react-firebase-hooks/database'
import {
  BB_BASE_URL,
  BB_PATIENT_DOCUMENT_GOOGLESHEET_ID,
  BB_STATUS_CASE_CLOSED,
  BB_STATUS_PAYMENT_COMPLETE,
} from '../../utils/constants'
import { onBuyClicked } from '../../utils/payment'
import {
  useGoogleSheet,
  useSingleLevelFBRTRef,
  useSingleLevelFilter,
  useSlack,
  useSpecialAccess,
} from '../hooks'
import { handleWebHook } from '../../utils'

// import GooglePayButton from '@google-pay/button-react'

interface PaymentProps extends RouteComponentProps {
  uid: string
  accessCode: string
  patientid: string
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})
const Payment: React.FC<PaymentProps> = ({
  uid: uniqueId,
  patientid,
}: PaymentProps) => {
  console.log('URW', uniqueId, patientid)

  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  // const [paymentsEntrySnapshot] = useObjectVal(
  //   firebase.database().ref(`status/${caseid}`)
  // )
  // const [paymentDone, setPaymentDone] = useState(false)
  // useEffect(() => {
  //   if (paymentsEntrySnapshot) {
  //     console.log('URW YAAAAAAAAAAAAAAY', paymentsEntrySnapshot)
  //     if (
  //       paymentsEntrySnapshot.status === BB_STATUS_PAYMENT_COMPLETE ||
  //       paymentsEntrySnapshot.status == BB_STATUS_CASE_CLOSED
  //     ) {
  //       setPaymentDone(true)
  //     } else {
  //       setPaymentDone(false)
  //     }
  //   }
  // }, [paymentsEntrySnapshot])

  const [googleDocumentData] = useGoogleSheet(
    BB_PATIENT_DOCUMENT_GOOGLESHEET_ID
  )
  console.log('DOCUU', googleDocumentData, patientid)

  const [accessSnapshot, isAccesssLoading] = useListVals(
    firebase.database().ref(`specialAccess`)
  )
  const [uid] = useSpecialAccess({
    data: accessSnapshot,
    uniqueId,
  })

  useSlack(
    `Document download with accessCode ${uniqueId} for Patient ${patientid}`
  )
  const [downloadSnapshot] = useList(
    firebase.database().ref(`downloads/${patientid}`)
  )
  const [downloadsData] = useSingleLevelFBRTRef(downloadSnapshot)
  console.log('URW snapshot', downloadsData)

  const [filteredData] = useSingleLevelFilter({
    data: downloadsData,
    dataFilter: {
      key: 'accessCode',
      value: uid,
    },
  })
  console.log('UUUUID', filteredData)
  const [filteredDocuments, setFilteredDocuments] = useState([])
  console.log('filteredDocuments', filteredDocuments)
  useEffect(() => {
    if (googleDocumentData.length && filteredData) {
      // const documents = downloadsData[0].documents.split(', ')
      const documents = filteredData?.documents.split(', ')
      const filtered = googleDocumentData.filter(
        item => documents?.indexOf(item['Title']) !== -1
      )
      setFilteredDocuments(filtered)
    }
  }, [googleDocumentData, filteredData])

  const [showDocument, setShowDocument] = useState(false)
  const [error, setError] = useState('')
  const accessCodeRef = useRef()
  const handleReady = () => {
    console.log('You are ready')
    if (uid === accessCodeRef.current.value) {
      setShowDocument(true)
    } else {
      accessCodeRef.current.value = null
      setError('Correct ')
    }
  }

  return (
    <section id="" className="major">
      <div className="inner">
        <header className="major">
          <h1>bloomB Document Download</h1>
        </header>
        <div className="content">
          <section id="login">
            <div className="inner">
              <section id="two">
                <div className="inner">
                  <header className="major"></header>
                  <p>
                    We are very pleased to serve you. We care about you and here
                    is what you can download as part of our General Management
                    instruction.
                  </p>
                  <p>
                    You can download this document multiple times, but have an
                    expiry of 10 days from date of generation.
                  </p>
                  <ul className="actions">
                    <li>
                      <input
                        type="text"
                        required
                        autoComplete="off"
                        placeholder={`Enter ${error}Access Code`}
                        ref={accessCodeRef}
                      />
                    </li>
                    <li>
                      <button className="button next" onClick={handleReady}>
                        I want to download
                      </button>
                    </li>
                  </ul>
                  {showDocument && filteredDocuments.length && (
                    <section>
                      <div className="box alt">
                        <div className="grid-wrapper">
                          {filteredDocuments.map((newRowData: any) => {
                            // console.log('STABID', newRowData)
                            // const imgUrl = getUrlParam(
                            //   newRowData['Clinic Prescription'],
                            //   'id'
                            // )

                            const downloadFiles = newRowData['Document'].split(
                              ','
                            )
                            return (
                              <div
                                className="col-4"
                                key={newRowData['Timestamp']}
                              >
                                <Card
                                  style={{
                                    margin: '10px 0',
                                  }}
                                >
                                  <CardContent>
                                    <Typography
                                      className={classes.title}
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      Patient ID : {patientid}
                                    </Typography>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      style={{ color: '#000' }}
                                    >
                                      {newRowData['Title']}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      {newRowData['Description']}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      style={{ height: '150px' }}
                                    >
                                      {downloadFiles.map((file, ind) => {
                                        return (
                                          <a
                                            className="button"
                                            style={{ color: '#000' }}
                                            href="#"
                                            target="_blank"
                                            onClick={e => {
                                              e.preventDefault()
                                              handleWebHook({
                                                text: `Patient ${patientid} just clicked on download link for ${newRowData['Title']} & ${file}`,
                                                channel: '#admins-only',
                                              })
                                              location.href = file
                                            }}
                                          >
                                            Download File {ind + 1}
                                          </a>
                                        )
                                      })}
                                    </Typography>
                                  </CardContent>
                                  {/* {downloadFiles.map((file, ind) => {
                                    return (
                                      <CardActions>
                                        <a
                                          className="button"
                                          style={{ color: '#000' }}
                                          href="#"
                                          target="_blank"
                                          onClick={e => {
                                            e.preventDefault()
                                            handleWebHook({
                                              text: `Patient ${patientid} just clicked on download link for ${newRowData['Title']} & ${file}`,
                                              channel: '#admins-only',
                                            })
                                            location.href = file
                                          }}
                                        >
                                          Download File {ind + 1}
                                        </a>
                                      </CardActions>
                                    )
                                  })} */}
                                </Card>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </section>
                  )}
                  {/* {paymentDone && (
                    <h4 style={{ color: '#87c5a4' }}>Payment is Complete!</h4>
                  )} */}
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Payment
