import React, { useContext, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { useMessage } from '../hooks'
import { bloomBLogin } from '../../services/firebase'
import { UserDispatchContext } from '../context/GlobalContextProvider'
import { handleWebHook } from '../../utils'

const Login: React.FC<RouteComponentProps> = () => {
  const [msg, setMsg] = useMessage()
  const dispatch = useContext<any>(UserDispatchContext)

  const emailAddress = useRef<any | null>(null)
  const password = useRef<any | null>(null)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    bloomBLogin({
      emailAddress: emailAddress.current.value,
      password: password.current.value,
    })
      .then(user => {
        dispatch({ type: 'SET_LOGGEDIN', payload: user })
        handleWebHook({
          text: `${emailAddress.current.value} just logged in...`,
        })
      })
      .then(() => {
        navigate('/app/dashboard', { replace: true })
      })
      .catch(function(error: any) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // ...
        console.log(errorCode, errorMessage)
        setMsg({ type: 'error', text: errorMessage })
      })
    // await globalStateContext.slack.send({
    //   text: `${emailAddress.current.value} just logged in..`,
    // })
  }
  const handleChange = (e: any) => {}

  return (
    <section id="" className="major">
      <div className="inner">
        <header className="major">
          <h1>Login</h1>
        </header>
        <div className="content">
          <section id="login">
            <div className="inner">
              <section>
                <form onSubmit={handleSubmit}>
                  <div className="field half first">
                    <label htmlFor="email">Email</label>
                    <input
                      ref={emailAddress}
                      type="email"
                      name="email"
                      id="email"
                      required
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="field half">
                    <label htmlFor="email">Password</label>
                    <input
                      ref={password}
                      type="password"
                      name="password"
                      id="password"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <ul className="actions">
                    <li>
                      <input type="submit" value="Login" className="special" />
                    </li>
                    <li>
                      <input type="reset" value="Clear" />
                    </li>
                    <li>
                      {msg && msg.text && (
                        <Message type={msg.type} message={msg.text} />
                      )}
                    </li>
                  </ul>
                </form>
                {/* <div className="field half first">
                  <Link to="/app/register">
                    <i>Don't have an account? Sign Up</i>
                  </Link>{' '}
                </div> */}
                <div className="field half first">
                  <Link to="/app/forgotpassword">
                    <sup>Forgot Password?</sup>
                  </Link>{' '}
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Login
