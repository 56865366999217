import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import Message from '../Message'
import { getUser, setOrUpdateRTdb } from '../../services/firebase'
import { useMessage } from '../hooks'

import {
  generateProfileId,
  getTimestamp,
  getUsername,
  handleWebHook,
  showNotification,
} from '../../utils'
import { useForm } from 'react-hook-form'
import {
  BB_APPOINTMENT_REQUEST,
  BB_MEDICINE_ONLY_REQUEST,
  BB_PENDING_STATUS,
} from '../../utils/constants'
import BannerGeneric from '../BannerGeneric'
import { useListVals } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { useSnackbar } from 'notistack'

const Registration: React.FC<RouteComponentProps> = () => {
  const { enqueueSnackbar } = useSnackbar()

  const user = getUser()
  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [requestType, setRequestType] = useState(BB_APPOINTMENT_REQUEST)

  const handleChange = (e: any) => {
    setRequestType(e.target.value)
  }

  const [msg, setMsg] = useMessage()
  const onSubmit = async (data: any) => {
    try {
      console.log('handleSubmit  action in progress!.....', data)
      const loggedInUsername = getUsername(user.accessProfile.username)
      const patientIdGenerated = await generateProfileId({
        data: bloomBUidsSnapshot,
      })
      const response = await setOrUpdateRTdb({
        ref: `patients/${getTimestamp()}`,
        data: {
          ...data,
          id: patientIdGenerated,
          updatedBy: loggedInUsername,
        },
      })
      handleWebHook({
        text: `New Patient Registration! Name : ${data.name}`,
        channel: '#general',
      })
      await setOrUpdateRTdb({
        ref: `queue/${getTimestamp()}`,
        data: {
          patientId: patientIdGenerated,
          name: data.name,
          requestType: requestType,
          status: BB_PENDING_STATUS,
          updatedBy: loggedInUsername,
        },
      })
      // dispatch({
      //   type: 'MESSAGE',
      //   payload: {
      //     type: 'success',
      //     text: 'New Patient Registration Successful!',
      //   },
      // })
      showNotification({
        enqueueSnackbar,
        text: 'New Patient Registration Successful!',
        type: 'success',
      })
      navigate('/app/patients')
    } catch (err) {
      setMsg({ type: 'error', text: err.message })
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }

  return (
    <>
      <BannerGeneric
        title="New Patient Registration"
        description="This is the first stage of bloomB work flow"
      >
        {
          <ul className="actions">
            <li>
              <button onClick={() => history.back()} className="button special">
                Back
              </button>
            </li>
            <li>
              <Link to={`/app/dashboard`} className="button special">
                Dashboard
              </Link>
            </li>
            <li>
              <Link to={`/app/patients`} className="button special">
                Patient Records
              </Link>
            </li>
            {/* <li>
              <Link to={`/app/responses`} className="button">
                Fetch Details
              </Link>
            </li> */}
          </ul>
        }
      </BannerGeneric>
      <section id="" className="submitform">
        <div className="inner">
          <div className="content">
            <section id="login">
              <div className="inner">
                <section>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="field half first">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        {...register('name', { required: true })}
                        autoComplete="off"
                      />
                    </div>
                    <div className="field half">
                      <label htmlFor="dob">
                        Date of Birth (eg: 25-09-1990)
                      </label>
                      <input
                        {...register('dob', { required: true })}
                        autoComplete="off"
                        type="text"
                      />
                    </div>
                    <div className="field half first">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        placeholder="contact@bloomb.co.in"
                        autoComplete="off"
                        {...register('email', { required: true })}
                      />
                    </div>
                    <div className="field half">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="text"
                        {...register('mobile', { required: true })}
                        autoComplete="off"
                      />
                    </div>
                    <div className="field half first">
                      <label htmlFor="address">Address</label>
                      <textarea
                        {...register('address', { required: true })}
                        autoComplete="off"
                        placeholder="Enter your message"
                        rows={6}
                      ></textarea>
                    </div>
                    <div className="field half">
                      <label htmlFor="initialComplaint">Initial Problem</label>
                      <textarea
                        {...register('initialComplaint', { required: true })}
                        autoComplete="off"
                        placeholder="Enter your message"
                        rows={6}
                      ></textarea>
                    </div>
                    <div className="field half first">
                      <input
                        onChange={handleChange}
                        type="radio"
                        id="appointmentRequest"
                        name="demo-priority"
                        value={BB_APPOINTMENT_REQUEST}
                        defaultChecked
                      />
                      <label htmlFor="appointmentRequest">
                        Appointment Request
                      </label>
                    </div>
                    <div className="field half">
                      <input
                        onChange={handleChange}
                        type="radio"
                        id="medicineRequest"
                        name="demo-priority"
                        value={BB_MEDICINE_ONLY_REQUEST}
                      />
                      <label htmlFor="medicineRequest">
                        Medicine-Only Request
                      </label>
                    </div>
                    <ul className="actions">
                      <li>
                        <input
                          type="submit"
                          value="Submit"
                          className="special"
                        />
                      </li>
                      <li>
                        <input type="reset" value="Clear" />
                      </li>
                      <li>
                        {msg && msg.text && (
                          <Message type={msg.type} message={msg.text} />
                        )}
                      </li>
                    </ul>
                  </form>
                </section>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Registration
