import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import { useSlack } from '../hooks'
import WorkflowComponent from '../WorkflowComponent'

const WorkflowUpdate: React.FC<RouteComponentProps> = () => {
  // const [display] = useApprovedContent([BB_DOCTOR])
  useSlack('Cases')

  return (
    <>
      <>
        <BannerGeneric title="Update Workflow" description="of cases">
          {
            <ul className="actions">
              <li>
                <Link to={`/app/dashboard`} className="button special">
                  Back to Dashboard
                </Link>
              </li>
            </ul>
          }
        </BannerGeneric>

        <WorkflowComponent />
      </>
    </>
  )
}

export default WorkflowUpdate
