import React, { useEffect, useState } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'

interface SearchProps {
  setSearchText: (text: string) => void
  searchText: string
  prefix?: boolean
}
const Search: React.FC<SearchProps> = ({
  searchText,
  setSearchText,
  prefix = false,
}) => {
  //   const [searchText, setSearchText] = useState('')

  const [values, setValues] = React.useState<{ search: string }>({
    search: '',
  })

  useEffect(() => {
    if (prefix) {
      if (values.search && values.search.length > 7) {
        setSearchText(`bB${values.search}`)
      } else {
        setSearchText('')
      }
    } else {
      setSearchText(`${values.search}`)
    }
  }, [values])

  const handleChange = (prop: any) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // strip off bB if exists at the beginning
    if (event.target.value.search('bB') === 0) {
      event.target.value = event.target.value.substr(2)
    }
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleInput = (e: any) => {
    console.log('On input', e.target.value)
  }
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="eg: APP12345"
          value={values.search}
          onChange={handleChange('search')}
          startAdornment={
            prefix ? <InputAdornment position="start">bB</InputAdornment> : null
          }
          labelWidth={60}
          OnInput={handleInput}
        />
      </FormControl>
    </div>
  )
}

export default Search
