import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import firebase from 'firebase'
import { useListVals } from 'react-firebase-hooks/database'
import { extractUid, isExpired } from '../utils'
import { BB_ACCESS_CODE_PREFIX } from '../utils/constants'

interface ProtectedRouteProps {
  location?: any
  component: React.FC
  path: string
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  console.log('PAYUUU PROT', rest)
  /*const [accessSnapshot, isAccesssLoading] = useListVals(
    firebase.database().ref(`specialAccess`)
  )

  const [uid, setUid] = useState('')
  useEffect(() => {
    if (rest.uid) {
      const uid = extractUid(rest.uid, BB_ACCESS_CODE_PREFIX)
      setUid(uid)
    }
  }, [rest])

  useEffect(() => {
    if (uid && accessSnapshot && !isAccesssLoading) {
      const response = accessSnapshot.some(item => {
        if (uid === item.accessCode && !isExpired(Number(item.expiry))) {
          return item
        }
      })
      if (!response) {
        navigate('/timeout')
      }
    }
  }, [uid, accessSnapshot, rest])*/

  return <Component {...rest} location={location} />
}
export default ProtectedRoute
