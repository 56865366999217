import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useSlack } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
)

export default function TransitionsModal({
  show = false,
  setShow,
  children,
  title,
}: {
  show: boolean
  setShow: (flag: boolean) => void
  children: React.ReactNode
  title: string
}) {
  useSlack(`${title} Modal window`)

  const classes = useStyles()
  const [open, setOpen] = React.useState(show)
  console.log('MODAL', open)
  // const handleOpen = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    setOpen(false)
    setShow(false)
  }

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper} style={{ color: '#000' }}>
              <h2 id="transition-modal-title">{title}</h2>
              {children}
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  )
}
