import React, { useEffect, useState } from 'react'
import { useList } from 'react-firebase-hooks/database'
import Chart from 'react-google-charts'
import firebase from 'gatsby-plugin-firebase'

import { Link } from 'gatsby'

// Reference : https://developers.google.com/chart/interactive/docs/gallery/timeline

const Timeline = () => {
  const [calendarSnapshot, isCalendarLoading, calendarError] = useList(
    firebase.database().ref(`calendar`)
  )

  const [chartsData, setChartsData] = useState<any[][]>([])
  console.log('CHARTS', chartsData)
  useEffect(() => {
    const availabilityData: any[][] = [
      [
        { type: 'string', id: 'Room' },
        { type: 'string', id: 'Name' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ],
    ]
    if (calendarSnapshot?.length) {
      calendarSnapshot?.map((item: any) => {
        console.log('CAL VAL()', Object.values(item.val()).length)
        if (Object.values(item.val()).length) {
          Object.values(item.val()).map((slot: any) => {
            console.log('CALI', slot)
            // const startHours = slot.startTime.split[':'][0]
            // const startMinutes = slot.startTime.split[':'][1]
            // const endHours = slot.endTime.split[':'][0]
            // const endMinutes = slot.endTime.split[':'][1]
            // const doctorsData = [
            //   'Availability',
            //   item.key,
            //   new Date(0, 0, 0, startHours, startMinutes, 0),
            //   new Date(0, 0, 0, endHours, endMinutes, 0),
            // ]
            // availabilityData.push(doctorsData)
          })
        }
        console.log('CAL Key', item.key)
        console.log('CAL Value', item.val())
      })
    }
    setChartsData(availabilityData)
  }, [calendarSnapshot])

  //   const columns = [
  //     { type: "string", id: "President" },
  //     { type: "date", id: "Start" },
  //     { type: "date", id: "End" },
  //   ];

  //   const rows = [
  //     ["Dr Shabana", new Date(1789, 3, 30), new Date(1797, 2, 4)],
  //     ["Dr Hanna", new Date(1797, 2, 4), new Date(1801, 2, 4)],
  //     ["Dr Naval", new Date(1801, 2, 4), new Date(1809, 2, 4)],
  //   ];

  //   const [state, setState] = useState({});
  //   useEffect(() => {
  //     setInterval(() => {
  //       setState({ refresh: Date.now() });
  //     }, 1000);
  //   }, []);

  const data = [
    [
      { type: 'string', id: 'Room' },
      { type: 'string', id: 'Name' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' },
    ],
    [
      'Availability',
      'Dr Shabana',
      new Date(0, 0, 0, 13, 0, 0),
      new Date(0, 0, 0, 21, 0, 0),
    ],
    [
      'Availability',
      'Dr Hanna',
      new Date(0, 0, 0, 12, 0, 0),
      new Date(0, 0, 0, 14, 0, 0),
    ],
    [
      'Availability',
      'Dr Sareena',
      new Date(0, 0, 0, 14, 0, 0),
      new Date(0, 0, 0, 16, 0, 0),
    ],
    [
      'Availability',
      'Dr Kripa',
      new Date(0, 0, 0, 11, 0, 0),
      new Date(0, 0, 0, 12, 30, 0),
    ],
    [
      'Availability',
      'Dr Nithya',
      new Date(0, 0, 0, 11, 30, 0),
      new Date(0, 0, 0, 13, 30, 0),
    ],
    [
      'Availability',
      'Dr Nithya',
      //   '<h2> Anything can go here </h2>',
      new Date(0, 0, 0, 15, 30, 0),
      new Date(0, 0, 0, 16, 30, 0),
    ],
    [
      'Availability',
      'Dr Naval',
      new Date(0, 0, 0, 10, 0, 0),
      new Date(0, 0, 0, 12, 0, 0),
    ],
    [
      'Availability',
      'Dr Muhsina',
      new Date(0, 0, 0, 11, 30, 0),
      new Date(0, 0, 0, 12, 30, 0),
    ],
    [
      'Availability',
      'Dr Muhsina',
      new Date(0, 0, 0, 16, 0, 0),
      new Date(0, 0, 0, 17, 0, 0),
    ],
    [
      'Availability',
      'Dr Habeeba',
      new Date(0, 0, 0, 16, 0, 0),
      new Date(0, 0, 0, 18, 0, 0),
    ],

    [
      'Availability',
      'Dr Rameeza',
      new Date(0, 0, 0, 19, 0, 0),
      new Date(0, 0, 0, 21, 0, 0),
    ],

    [
      'Availability',
      'Dr Sabeena',
      new Date(0, 0, 0, 17, 0, 0),
      new Date(0, 0, 0, 18, 0, 0),
    ],
  ]
  return (
    <>
      <section id="two" style={{ backgroundColor: '#87c5a4' }}>
        <div className="inner">
          <header className="major">
            <h2>Availability Calendar</h2>
          </header>
          <Chart
            width={'100%'}
            height={'200px'}
            chartType="Timeline"
            loader={<div>Checking Doctors' Availability...</div>}
            data={data}
            options={{
              timeline: { showRowLabels: false },
              avoidOverlappingGridLines: false,
              allowHtml: true,
              backgroundColor: '#87c5a4',
            }}
            rootProps={{ 'data-testid': '9' }}
          />
          <ul className="actions">
            <li>
              <Link to="/team" className="button next">
                View Profiles
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default Timeline
