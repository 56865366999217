import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { getNameAddress, getUsername, trimText } from '../utils'
import RestrictedButton from './RestrictedButton'
import {
  BB_ADMIN_USERNAME,
  BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID,
  BB_PHARMACIST,
} from '../utils/constants'
import { useGoogleSheet, useLoggedInUser, useMultiLevelFBRTRef } from './hooks'
import { useList } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { isApprovedRole } from '../services/firebase'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default function OutlinedCard() {
  const [loggedInUsername] = useLoggedInUser()

  const [
    caseHistorySnapshot,
    isCaseHistorysLoading,
    caseHistorysError,
  ] = useList(firebase.database().ref(`casehistory`))

  const [multiLevelData] = useMultiLevelFBRTRef(caseHistorySnapshot)
  console.log('PRES', multiLevelData)

  const [googleData] = useGoogleSheet(BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID)
  console.log('Prescriptions', googleData)

  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  return (
    <>
      <section>
        <div className="box alt">
          <div className="grid-wrapper">
            {multiLevelData.map((newRowData: any) => {
              const filteredData = getNameAddress({
                data: googleData,
                dataFilter: {
                  key: 'Request ID',
                  value: newRowData['Request ID'],
                },
              })
              console.log('Prescriptions Filter', filteredData)
              if (!filteredData) {
                return (
                  <div className="col-4" key={newRowData['Timestamp']}>
                    <Card style={{ margin: '10px 0' }}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Request ID : {newRowData['Request ID']}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            className={classes.pos}
                            color="textSecondary"
                          >
                            {getUsername(newRowData["Doctor's bloomB ID"])}'s
                            Case
                          </Typography>
                          <LocalHospitalIcon />
                        </div>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ height: '160px', overflow: 'hidden' }}
                        >
                          Rx : {trimText(newRowData['Rx'], 400)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        {(isApprovedRole([BB_PHARMACIST]) ||
                          loggedInUsername === BB_ADMIN_USERNAME) && (
                          <RestrictedButton
                            text="Prescription"
                            roles={[BB_PHARMACIST]}
                            linkTo={`/app/prescription`}
                            target={true}
                            state={{
                              googleData: newRowData,
                              priceEditable: true,
                              deliveryEditable: true,
                              totalEditable: true,
                              amountEditable: true,
                              generateInvoice: true,
                            }}
                          />
                        )}
                      </CardActions>
                    </Card>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </section>
    </>
  )
}
