import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useApprovedContent } from '../hooks'
import BannerGeneric from '../BannerGeneric'
import { Seo } from '../Seo'
import {
  BB_CASE_HISTORY_FORM,
  BB_CLINIC_PRESCRIPTION_FORM,
  BB_DOCTOR,
  BB_PHARMACIST,
} from '../../utils/constants'
import RestrictedButton from '../RestrictedButton'
import Tile from '../Tile'

const Dashboard: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_PHARMACIST])
  return (
    <>
      {display && (
        <>
          <Seo title="Dashboard" />
          <BannerGeneric
            title="Pharmacist's Corner"
            description="This is where bloomB Pharmacists engage"
          >
            <ul className="actions">
              <li>
                <Link to={`/app/dashboard`} className="button special">
                  Back to Dashboard
                </Link>
              </li>
              <li>
                <RestrictedButton
                  text="Generate Prescription"
                  roles={[BB_PHARMACIST]}
                  linkTo={`/app/cases`}
                  target={true}
                  state={{
                    priceEditable: true,
                  }}
                />
              </li>
              <li>
                <RestrictedButton
                  text="Upload Clinic Prescription"
                  roles={[BB_PHARMACIST]}
                  linkTo={BB_CLINIC_PRESCRIPTION_FORM}
                  target={true}
                />
              </li>
              <li>
                <RestrictedButton
                  text="CHECK STATUS"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_CASE_HISTORY_FORM}
                />
              </li>
            </ul>
          </BannerGeneric>
          <div id="main">
            <section id="one" className="tiles">
              <Tile
                title="Prescriptions"
                subtitle="To be Invoiced & Dispensed"
                linkTo="/app/prescriptions"
              />
              {/* <PharmacistOnly /> */}
            </section>
            {/* <HROnly /> */}
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
