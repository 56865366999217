import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useApprovedContent } from '../hooks'
import BannerGeneric from '../BannerGeneric'
import { Seo } from '../Seo'
import PharmacistOnly from '../PharmacistOnly'
import HROnly from '../HROnly'
import {
  BB_CASE_FOLLOWUP_FORM,
  BB_CASE_HISTORY_FORM,
  BB_DOCTOR,
  BB_QUICK_REFERENCES_FORM,
} from '../../utils/constants'
import RestrictedButton from '../RestrictedButton'
import Tile from '../Tile'

const Dashboard: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_DOCTOR])
  return (
    <>
      {display && (
        <>
          <Seo title="Dashboard" />
          <BannerGeneric
            title="Doctors' Corner"
            description="This is where bloomB Doctors engage"
          >
            <ul className="actions">
              <li>
                <Link to={`/app/dashboard`} className="button special">
                  Back to Dashboard
                </Link>
              </li>
              <li>
                <RestrictedButton
                  text="Record Case History"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_CASE_HISTORY_FORM}
                  target={true}
                />
              </li>
              <li>
                <RestrictedButton
                  text="Case Follow-up Form"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_CASE_FOLLOWUP_FORM}
                  target={true}
                />
              </li>
              <li>
                <Link to={`/app/status`} className="button special">
                  Check Status
                </Link>
              </li>
              <li>
                <RestrictedButton
                  text="Add References"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_QUICK_REFERENCES_FORM}
                  target={true}
                />
              </li>
            </ul>
          </BannerGeneric>
          <div id="main">
            <section id="one" className="tiles">
              <Tile
                title="Cases"
                subtitle="Patient Case Records"
                linkTo="/app/cases"
              />
              <Tile
                title="Quick References"
                subtitle="For Future"
                linkTo="/app/reference"
              />
              <PharmacistOnly />
            </section>
            <HROnly />
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
