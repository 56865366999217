import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'

import { useList } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { useSnapShotForTable } from '../hooks'
import { BB_REGISTERED_DATE } from '../../utils/constants'
import Datatable from '../Datatable'
import BannerGeneric from '../BannerGeneric'

const Registration: React.FC<RouteComponentProps> = () => {
  const [patientsSnapshot, isPatientsLoading, patientsError] = useList(
    firebase.database().ref(`patients`)
  )
  const columns = [
    'name',
    'id',
    'dob',
    'email',
    'mobile',
    'address',
    'initialComplaint',
    'updatedBy',
    BB_REGISTERED_DATE,
  ]
  const [datatableData] = useSnapShotForTable({
    data: patientsSnapshot,
    columns,
  })

  const datatableColumns = [
    'Name',
    'Patient ID',
    'Dob',
    'Email',
    'Mobile',
    'Address',
    'Initial Complaint',
    'Updated By',
    BB_REGISTERED_DATE,
  ]
  // const [patientsData, setPatientsData] = useState<string[][]>([])
  // useEffect(() => {
  //   if (patientsSnapshot?.length && !isPatientsLoading) {
  //     let tableData: string[][] = []
  //     patientsSnapshot?.map((item: any) => {
  //       console.log('Key', item.key)
  //       console.log('Value', item.val().name)
  //       const dt = [
  //         item.val().name,
  //         item.val().id,
  //         item.val().email,
  //         item.val().mobile,
  //       ]
  //       tableData.push(dt)
  //       setPatientsData(tableData)
  //     })
  //   }
  // }, [patientsSnapshot, isPatientsLoading])

  const options = {
    filterType: 'checkbox',
  }

  return (
    <>
      <BannerGeneric
        title="Patient Records"
        description="This is where patient registrations are stored"
      >
        {
          <ul className="actions">
            <li>
              <Link to={`/app/dashboard`} className="button special">
                Back to Dashboard
              </Link>
            </li>
          </ul>
        }
      </BannerGeneric>
      <section
        id=""
        className="submitform"
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <div className="inner">
          <div className="content">
            <section id="login">
              {datatableData && (
                <Datatable
                  title={''}
                  data={datatableData}
                  columns={datatableColumns}
                />
              )}
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Registration
