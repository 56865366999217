import React from 'react'
import { BB_DOCTOR } from '../utils/constants'
import { useApprovedContent } from './hooks'
import Tile from './Tile'

const DoctorOnly = () => {
  const [display] = useApprovedContent([BB_DOCTOR])

  return (
    <>
      {display && (
        <Tile
          title="Doctors' Corner"
          subtitle="Exclusive"
          linkTo="/app/doctors"
        />
      )}
    </>
  )
}

export default DoctorOnly
