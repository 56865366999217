import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import Requests from '../Requests'
import { BB_REGISTERED_DATE } from '../../utils/constants'

const Appointments: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <BannerGeneric
        title="Appointments"
        description="This is where all appointments are listed"
      >
        {
          <ul className="actions">
            <li>
              <Link to={`/app/dashboard`} className="button special">
                Back to Dashboard
              </Link>
            </li>
          </ul>
        }
      </BannerGeneric>
      <Requests
        columns={[
          'patientId',
          'requestType',
          'status',
          'updatedBy',
          BB_REGISTERED_DATE,
        ]}
        table="queue"
        tableColumns={[
          'Patient ID',
          'Request Type',
          'Status',
          'Updated By',
          BB_REGISTERED_DATE,
        ]}
        title="Requests/Reminders"
      />
    </>
  )
}

export default Appointments
