import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface CopyTextProps {
  text: string
  styles?: {
    [key: string]: string
  }
}
const CopyText: React.FC<CopyTextProps> = ({ text, styles }) => {
  const [copied, setCopied] = useState(false)

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)} style={styles}>
      <button className="small button special">
        {copied ? `COPIED` : `copy`}
      </button>
    </CopyToClipboard>
  )
}

export default CopyText
