import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useObjectVal } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import ShowModal from './ShowModal'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '60px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface CompleteWorkflowProps {
  statusCodes?: any
}
const CompleteWorkflow: React.FC<CompleteWorkflowProps> = ({ statusCodes }) => {
  const classes = useStyles()
  const [
    workflowStatusSnapshot,
    isWorkflowStatussLoading,
    workflowStatussError,
  ] = useObjectVal(firebase.database().ref(`workflowLevels`))
  const [workflowLevels, setWorkflowLevels] = useState([])
  console.log('STATUS 10000', workflowLevels)
  useEffect(() => {
    if (workflowStatusSnapshot) {
      const workflowLevelsArray = Object.entries(workflowStatusSnapshot)
      setWorkflowLevels(workflowLevelsArray)
    }
  }, [workflowStatusSnapshot])
  return (
    <ShowModal buttonLabel="View Complete Workflow" title={`Complete Workflow`}>
      <div style={{ height: '50vh', overflow: 'scroll' }}>
        <Timeline align="alternate" className={classes.root}>
          {workflowLevels.map(item => {
            return (
              <TimelineItem key={item.key}>
                {(Number(item[0]) === 58 || Number(item[0]) === 103) && (
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      OR
                    </Typography>
                  </TimelineOppositeContent>
                )}
                <TimelineSeparator>
                  <TimelineDot
                    color={
                      statusCodes && statusCodes.indexOf(Number(item[0])) !== -1
                        ? 'secondary'
                        : statusCodes &&
                          Number(item[0]) > statusCodes[statusCodes.length - 1]
                        ? ''
                        : 'primary'
                    }
                  ></TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {item[1]}
                    </Typography>
                    {/* <Typography>
                Discuss your problem
              </Typography> */}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      </div>
    </ShowModal>
  )
}

export default CompleteWorkflow
