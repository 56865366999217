import React, { useState } from 'react'
import {
  BB_STATUS_APPOINTMENT_FIXED,
  BB_STATUS_CONSULTATION_COMPLETE,
  BB_STATUS_FEEDBACK_RECEIVED,
  BB_STATUS_FEEDBACK_REQUESTED,
  BB_STATUS_MEDICINE_DISPENSED_DESPATCHED,
  BB_STATUS_MEDICINE_PRESCRIPTION_UPLOADED,
  BB_STATUS_MEDONLY_REQUEST_INITIATED,
  BB_STATUS_PAYMENT_COMPLETE,
  BB_STATUS_PAYMENT_DEFERRED,
  BB_STATUS_PAYMENT_DUE_INFORMED,
  BB_STATUS_PAYMENT_LINK_GENERATED,
  BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST,
} from '../utils/constants'
import CaseHistoryUpdate from './CaseHistoryUpdate'
import ConsultationUpdate from './ConsultationUpdate'
import FeedbackUpdate from './FeedbackUpdate'
import MedsDispenseUpdate from './MedsDispenseUpdate'
import PaymentUpdate from './PaymentUpdate'

export default function WorkflowComponent({
  type,
  data,
}: {
  type: number
  data: any
}) {
  const [displayComp, setDisplayComp] = useState(type)

  return (
    <>
      {displayComp === BB_STATUS_APPOINTMENT_FIXED && (
        <ConsultationUpdate data={data} />
      )}
      {(displayComp === BB_STATUS_CONSULTATION_COMPLETE ||
        displayComp === BB_STATUS_MEDONLY_REQUEST_INITIATED) && (
        <CaseHistoryUpdate data={data} current={displayComp} />
      )}
      {(displayComp === BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST ||
        displayComp === BB_STATUS_MEDICINE_DISPENSED_DESPATCHED ||
        displayComp === BB_STATUS_MEDICINE_PRESCRIPTION_UPLOADED) && (
        <MedsDispenseUpdate data={data} current={displayComp} />
      )}
      {(displayComp === BB_STATUS_PAYMENT_LINK_GENERATED ||
        displayComp === BB_STATUS_PAYMENT_DUE_INFORMED ||
        displayComp === BB_STATUS_PAYMENT_DEFERRED) && (
        <PaymentUpdate data={data} current={displayComp} />
      )}
      {(displayComp === BB_STATUS_PAYMENT_COMPLETE ||
        displayComp === BB_STATUS_FEEDBACK_REQUESTED ||
        displayComp === BB_STATUS_FEEDBACK_RECEIVED) && (
        <FeedbackUpdate data={data} current={displayComp} />
      )}
    </>
  )
}
