import { useMachine } from '@xstate/react'
import React from 'react'
import {
  BB_STATUS_MEDICINE_DISPENSED_DESPATCHED,
  BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST,
} from '../utils/constants'
import { medicineMachine } from './stateMachine/medicine'
import WorkflowTimeline from './WorkflowTimeline'

interface ConsultationUpdateProps {
  data: any
  current: number
}
const ConsultationUpdate: React.FC<ConsultationUpdateProps> = ({
  data,
  current: currentStatus,
}) => {
  const [current, send] = useMachine(medicineMachine, {
    context: {
      data,
    },
  })
  let actionNext: string
  if (currentStatus === BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST) {
    actionNext = 'DISPENSED'
  } else if (currentStatus === BB_STATUS_MEDICINE_DISPENSED_DESPATCHED) {
    actionNext = 'UPLOADED'
  } else {
    actionNext = 'TRACKED'
  }
  return (
    <>
      <WorkflowTimeline
        requestId={data.key}
        current={current}
        stage="medicine"
        data={[
          {
            button: actionNext,
            text: 'Click to update if Medicine is dispensed & despatched',
            action: () => send(actionNext),
          },
        ]}
      />
    </>
  )
}

export default ConsultationUpdate
