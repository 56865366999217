import React from 'react'
import { BB_PHARMACIST } from '../utils/constants'
import { useApprovedContent } from './hooks'
import Tile from './Tile'

const PharmacistOnly = () => {
  const [display] = useApprovedContent([BB_PHARMACIST])

  return (
    <>
      {display && (
        <Tile
          title="Pharmacist Only"
          subtitle="Exclusive"
          linkTo="/app/pharmacy"
        />
      )}
    </>
  )
}

export default PharmacistOnly
