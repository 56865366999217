import { RouteComponentProps } from '@reach/router'
import firebase from 'gatsby-plugin-firebase'
import React, { useEffect, useRef, useState } from 'react'
import {
  useList,
  useListVals,
  useObjectVal,
} from 'react-firebase-hooks/database'
import {
  BB_BASE_URL,
  BB_STATUS_CASE_CLOSED,
  BB_STATUS_PAYMENT_COMPLETE,
} from '../../utils/constants'
import { onBuyClicked } from '../../utils/payment'
import {
  useSingleLevelFBRTRef,
  useSingleLevelFilter,
  useSlack,
  useSpecialAccess,
} from '../hooks'

// import GooglePayButton from '@google-pay/button-react'

interface PaymentProps extends RouteComponentProps {
  uid: string
  accessCode: string
  caseid: string
}
const Payment: React.FC<PaymentProps> = ({
  uid: uniqueId,
  caseid,
}: PaymentProps) => {
  console.log('URW', uniqueId, caseid)

  const [paymentsEntrySnapshot] = useObjectVal(
    firebase.database().ref(`status/${caseid}`)
  )
  const [paymentDone, setPaymentDone] = useState(false)
  useEffect(() => {
    if (paymentsEntrySnapshot) {
      console.log('URW YAAAAAAAAAAAAAAY', paymentsEntrySnapshot)
      if (
        paymentsEntrySnapshot.status === BB_STATUS_PAYMENT_COMPLETE ||
        paymentsEntrySnapshot.status == BB_STATUS_CASE_CLOSED
      ) {
        setPaymentDone(true)
      } else {
        setPaymentDone(false)
      }
    }
  }, [paymentsEntrySnapshot])

  const [accessSnapshot, isAccesssLoading] = useListVals(
    firebase.database().ref(`specialAccess`)
  )

  useSlack(`Payment with accessCode ${uniqueId}`)
  const [paymentsSnapshot, isPaymentsLoading] = useList(
    firebase.database().ref(`payments`)
  )
  const [paymentsData] = useSingleLevelFBRTRef(paymentsSnapshot)
  console.log('URW snapshot', paymentsData)
  const [uid] = useSpecialAccess({
    data: accessSnapshot,
    uniqueId,
  })
  const [filteredData] = useSingleLevelFilter({
    data: paymentsData,
    dataFilter: {
      key: 'accessCode',
      value: uid,
    },
  })
  console.log('UUUUID', filteredData)

  const [showGPay, setShowGPay] = useState(false)
  const [error, setError] = useState('')
  const accessCodeRef = useRef()
  const handleReady = () => {
    console.log('You are ready')
    if (uid === accessCodeRef.current.value) {
      setShowGPay(true)
    } else {
      accessCodeRef.current.value = null
      setError('Correct ')
    }
  }

  return (
    <section id="" className="major">
      <div className="inner">
        <header className="major">
          <h1>bloomB Payment</h1>
        </header>
        <div className="content">
          <section id="login">
            <div className="inner">
              <section id="two">
                <div className="inner">
                  <header className="major"></header>
                  <p>
                    We are delighted to serve you. Please proceed with below
                    payment. Click the button below for Google Payment as that
                    is the only mode of payment currently supported in this
                    platform.
                  </p>
                  <p>
                    Currently, the below payment will only work for Android
                    devices with Chrome.
                  </p>
                  <ul className="actions">
                    <li>
                      <input
                        type="text"
                        required
                        autoComplete="off"
                        placeholder={`Enter ${error}Access Code`}
                        ref={accessCodeRef}
                      />
                    </li>
                    <li>
                      <button className="button next" onClick={handleReady}>
                        Ready for Payment
                      </button>
                    </li>
                  </ul>
                  {showGPay && !paymentDone && (
                    <p>
                      <button
                        className="button special"
                        onClick={() =>
                          onBuyClicked({
                            url: `${BB_BASE_URL}/app/payinvoice/uniqueId`,
                            amount: filteredData.netAmount,
                            data: filteredData,
                          })
                        }
                      >
                        Make Payment
                      </button>
                      {/* <button className="button special" onClick={handleReady}>
                        G Pay
                      </button> */}
                      {/* <GooglePayButton
                        environment="TEST"
                        paymentRequest={{
                          apiVersion: 2,
                          apiVersionMinor: 0,
                          allowedPaymentMethods: [
                            {
                              type: 'CARD',
                              parameters: {
                                allowedAuthMethods: [
                                  'PAN_ONLY',
                                  'CRYPTOGRAM_3DS',
                                ],
                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                              },
                              tokenizationSpecification: {
                                type: 'PAYMENT_GATEWAY',
                                parameters: {
                                  gateway: 'example',
                                },
                              },
                            },
                          ],
                          merchantInfo: {
                            // merchantId: '12345678901234567890',
                            merchantId: 'BCR2DN6TV6EYZX2W',
                            merchantName: 'The Bloom Bunch',
                          },
                          transactionInfo: {
                            totalPriceStatus: 'FINAL',
                            totalPriceLabel: 'Total',
                            totalPrice: '100.00',
                            currencyCode: 'USD',
                            countryCode: 'US',
                          },
                        }}
                        onLoadPaymentData={paymentRequest => {
                          console.log('load payment data', paymentRequest)
                        }}
                      /> */}
                    </p>
                  )}
                  {paymentDone && (
                    <h4 style={{ color: '#87c5a4' }}>Payment is Complete!</h4>
                  )}
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Payment
