import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import { Seo } from '../Seo'
import DoctorOnly from '../DoctorOnly'
import PharmacistOnly from '../PharmacistOnly'
import HROnly from '../HROnly'
import Tile from '../Tile'
import AdminOnly from '../AdminOnly'

const SpecialPrivileges: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <>
        <Seo title="Special Privileges" />
        <BannerGeneric
          title={`Special You`}
          description="You have additional privileges"
        >
          <ul className="actions">
            <li>
              <Link to={`/app/dashboard`} className="button special">
                Back to Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={`/app/special`}
                className="button"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Link>
            </li>
          </ul>
        </BannerGeneric>
        <div id="main">
          <section id="one" className="tiles">
            {/* <Tile
              title="Requests"
              subtitle="Both Appointment/Medine-only ones"
              linkTo="/app/requests"
            /> */}
            {/* <Tile
              title="Appointments"
              subtitle="For All Listings"
              linkTo="/app/appointments"
            /> */}
            <AdminOnly />
            <DoctorOnly />
            <PharmacistOnly />
            <Tile
              title="Workflow Updates"
              subtitle="Check & Update Status"
              linkTo="/app/workflow"
            />
            <Tile
              title="Cases"
              subtitle="Patient Case Records"
              linkTo="/app/cases"
            />
            <Tile
              title="Follow-up Cases"
              subtitle="Follow-up Case Records"
              linkTo="/app/followups"
            />
            <Tile
              title="What I earned"
              subtitle="From bloomB"
              linkTo="/app/earnings"
            />
            {/* <Tile
              title="Registration"
              subtitle="For New Patients"
              linkTo="/app/registration"
            /> */}
            {/* <Tile
              title="Patients"
              subtitle="Search Records"
              linkTo="/app/patients"
            /> */}
          </section>
          <HROnly />
        </div>
      </>
    </>
  )
}

export default SpecialPrivileges
