import React from 'react'
import { BB_ADMIN } from '../utils/constants'
import { useApprovedContent } from './hooks'
import Tile from './Tile'

const DoctorOnly = () => {
  const [display] = useApprovedContent([BB_ADMIN])

  return (
    <>
      {display && (
        <Tile
          title="Admin's Corner"
          subtitle="Confidential"
          linkTo="/app/admin"
        />
      )}
    </>
  )
}

export default DoctorOnly
