import { createMachine } from 'xstate'
import { handleStatusHook } from '../../services/firebase'
import {
  BB_STATUS_MEDICINE_DISPENSED_DESPATCHED,
  BB_STATUS_MEDICINE_PRESCRIPTION_UPLOADED,
  BB_STATUS_MEDICINE_TRACKING_INFORMED,
} from '../../utils/constants'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'medicine'; context: { data: any } }
  | { value: 'dispensed'; context: { data: any } }

const logMsg = () => console.log('Creating & Requesting...')

export const medicineMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'recordWorkflows',
  initial: 'medicine',
  states: {
    medicine: {
      on: {
        DISPENSED: 'dispensed',
        UPLOADED: 'uploaded',
        TRACKED: 'tracked',
      },
    },
    dispensed: {
      invoke: {
        id: 'updateDispensed',
        src: (context, event) => {
          console.log(
            'XSTATE Creating & Requesting updateDispensed...',
            context
          )
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_MEDICINE_DISPENSED_DESPATCHED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    uploaded: {
      invoke: {
        id: 'updateUploaded',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateUploaded...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_MEDICINE_PRESCRIPTION_UPLOADED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    tracked: {
      invoke: {
        id: 'updateTracked',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateTracked...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_MEDICINE_TRACKING_INFORMED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      type: 'final',
    },
  },
})
