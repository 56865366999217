import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/firebase'
import { useSnackbar } from 'notistack'
import {
  getSessionStorage,
  setSessionStorage,
  showNotification,
} from '../utils'
import { useObjectVal } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { BB_SS_MESSAGE } from '../utils/constants'
interface PrivateRouteProps {
  location?: any
  component: React.FC
  path: string
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const [snapshot] = useObjectVal(firebase.database().ref('message'))
  console.log('MSG', snapshot)
  const { enqueueSnackbar } = useSnackbar()

  /* To be moved to custom hooks */
  const messageSnapShot = getSessionStorage(BB_SS_MESSAGE)
  const [broadcast] = useState(messageSnapShot)
  useEffect(() => {
    if (snapshot && !broadcast?.text) {
      showNotification({
        enqueueSnackbar,
        text: (
          <div
            dangerouslySetInnerHTML={{
              __html: snapshot?.text,
            }}
          />
        ),
        type: snapshot?.type,
      })
      setSessionStorage(BB_SS_MESSAGE, snapshot)
    }
  }, [snapshot, broadcast])
  /* To be moved to custom hooks */

  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    console.log('redirecting to login')
    navigate('/app/login')
    return null
  }
  return <Component {...rest} location={location} />
}
export default PrivateRoute
