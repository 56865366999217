import React, { useState } from 'react'
import ModalWindow from './ModalWindow'

interface ShowModalProps {
  buttonLabel: string
  title: string
  children: React.ReactNode
}
const ShowModal: React.FC<ShowModalProps> = ({
  buttonLabel,
  title,
  children,
}) => {
  const [modal, setModal] = useState(false)
  console.log('MODAL PARENT', modal)
  const handleOpen = () => {
    setModal(!modal)
  }
  return (
    <>
      <button
        type="button"
        className="small button special"
        onClick={handleOpen}
      >
        {buttonLabel}
      </button>
      {modal && (
        <ModalWindow show={modal} setShow={setModal} title={title}>
          {children}
        </ModalWindow>
      )}
    </>
  )
}

export default ShowModal
