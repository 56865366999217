import React from 'react'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import { showNotification } from '../utils'

export default function MyApp() {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <Button
        onClick={() =>
          showNotification({
            enqueueSnackbar,
            text: 'i love you',
          })
        }
      >
        Show snackbar
      </Button>
      <Button
        onClick={() =>
          showNotification({
            enqueueSnackbar,
            text:
              'I love material uiI love material uiI love material uiI love material uiI love material uiI love material uiI love material uiI love material ui',
            type: 'warning',
          })
        }
      >
        Show success snackbar
      </Button>
    </>
  )
}
