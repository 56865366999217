import { createMachine } from 'xstate'
import { handlePharmaHelp, handleStatusHook } from '../../services/firebase'
import { BB_STATUS_RECORDED_CASE_HISTORY } from '../../utils/constants'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'record'; context: { data: any } }
  | { value: 'recorded'; context: { data: any } }

const logMsg = () => console.log('Creating & Requesting...')

export const recordMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'recordWorkflows',
  initial: 'record',
  states: {
    record: {
      on: {
        RECORDED: 'recorded',
      },
    },
    recorded: {
      invoke: {
        id: 'updateRecorded',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateRecorded...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_RECORDED_CASE_HISTORY)
          handlePharmaHelp(data, BB_STATUS_RECORDED_CASE_HISTORY)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      type: 'final',
    },
  },
})
