import { createMachine } from 'xstate'
import { handlePharmaHelp, handleStatusHook } from '../../services/firebase'
import {
  BB_STATUS_CONSULTATION_COMPLETE,
  BB_STATUS_NO_SHOW,
} from '../../utils/constants'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'consultation'; context: { data: any } }
  | { value: 'complete'; context: { data: any } }
  | { value: 'noshow'; context: { data: any } }

const logMsg = () => console.log('Creating & Requesting...')

export const consultationMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'consultationWorkflows',
  initial: 'consultation',
  states: {
    consultation: {
      on: {
        COMPLETE: 'complete',
        NOSHOW: 'noshow',
      },
    },
    complete: {
      invoke: {
        id: 'updateComplete',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateComplete...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_CONSULTATION_COMPLETE)
          handlePharmaHelp(data, BB_STATUS_CONSULTATION_COMPLETE)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    noshow: {
      invoke: {
        id: 'updateNoshow',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateNoshow...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_NO_SHOW)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      type: 'final',
    },
  },
})
