import React from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { Button } from '@material-ui/core'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > span': {
        margin: theme.spacing(2),
      },
    },
    button: {
      margin: theme.spacing(1),
    },
  })
)

interface handleUpdateProps {
  handleUpdate: () => void
}
const UpdateButton: React.FC<handleUpdateProps> = ({ handleUpdate }) => {
  const classes = useStyles()

  return (
    <ul className="actions">
      <li>
        {/* <Icon
          className="fa fa-plus-circle"
          color="error"
          style={{ fontSize: '60px' }}
          onClick={handleUpdate}
        /> */}
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<LocalHospitalIcon />}
          onClick={handleUpdate}
        >
          Update me before you screenshot
        </Button>
      </li>
    </ul>
  )
}

export default UpdateButton
