import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Alert, { Color } from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
)

export default function SimpleAlerts({
  type,
  text,
}: {
  type: Color | undefined
  text: string
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* <Alert severity="error">This is an error alert — check it out!</Alert>
      <Alert severity="warning">This is a warning alert — check it out!</Alert> */}
      <Alert severity={type}>{text}</Alert>
      {/* <Alert severity="success">This is a success alert — check it out!</Alert> */}
    </div>
  )
}
