import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useApprovedContent } from '../hooks'
import BannerGeneric from '../BannerGeneric'
import { Seo } from '../Seo'
import HROnly from '../HROnly'
import {
  BB_ADMIN,
  BB_CASE_HISTORY_FORM,
  BB_DOCTOR,
  BB_PATIENT_DOCUMENT_UPLOAD_FORM,
} from '../../utils/constants'
import RestrictedButton from '../RestrictedButton'
import Tile from '../Tile'
import { useMachine } from '@xstate/react'
import { workflowMachine } from '../stateMachine/machine'
import NotificationBar from '../NotificationBar'

const Dashboard: React.FC<RouteComponentProps> = () => {
  const [display] = useApprovedContent([BB_ADMIN])
  const [current, send] = useMachine(workflowMachine)
  console.log('XSTATE', current)
  return (
    <>
      {display && (
        <>
          <Seo title="Dashboard" />
          <BannerGeneric
            title="Admin's Corner"
            description="This is where bloomB Admin engage"
          >
            <ul className="actions">
              <li>
                <RestrictedButton
                  text="Record Case History"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_CASE_HISTORY_FORM}
                  target={true}
                />
              </li>
              <li>
                <RestrictedButton
                  text="Release Patient Document"
                  roles={[BB_ADMIN]}
                  linkTo={`/app/document`}
                />
              </li>
              <li>
                <RestrictedButton
                  text="Upload Patient Documents"
                  roles={[BB_ADMIN]}
                  linkTo={BB_PATIENT_DOCUMENT_UPLOAD_FORM}
                  target={true}
                />
              </li>
            </ul>
          </BannerGeneric>
          <div id="main">
            <section id="one" className="tiles">
              <Tile
                title="Invoice"
                subtitle="Create Payment Request"
                linkTo="/app/invoice"
              />
              <Tile
                title="Medicine-Only Request"
                subtitle="Exclusive"
                linkTo="/app/medsrequest"
              />
              <button onClick={() => send('NEXT')}>{current.value}</button>
            </section>
            <HROnly />
            <NotificationBar />
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
