import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import { useSlack } from '../hooks'
import Status from '../Status'

const Registration: React.FC<RouteComponentProps> = () => {
  useSlack('Status')

  return (
    <>
      <>
        <BannerGeneric title="Case Status" description="The workflow status">
          {
            <ul className="actions">
              <li>
                <Link to={`/app/dashboard`} className="button special">
                  Back to Dashboard
                </Link>
              </li>
              <li>
                <button
                  onClick={() => history.back()}
                  className="button special"
                >
                  Back
                </button>
              </li>
              <li>
                <Link to={`/app/appointments`} className="button special">
                  View Appointments
                </Link>
              </li>
            </ul>
          }
        </BannerGeneric>

        <Status />
      </>
    </>
  )
}

export default Registration
