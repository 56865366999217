import { RouteComponentProps } from '@reach/router'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { getUrlParam, getUsername } from '../../utils'
import { AccordionProps } from '../../utils/types'
import AccordionDisplay from '../AccordionDisplay'
import BannerGeneric from '../BannerGeneric'
import Comments from '../Comments'
import { useSlack } from '../hooks'
import RestrictedButton from '../RestrictedButton'
import { BB_DOCTOR } from '../../utils/constants'

const Details: React.FC<RouteComponentProps> = ({ location }) => {
  const {
    state: { data, loggedInUsername },
  } = location

  useSlack(`Case Details of REQ : ${data['Request ID']}`)

  console.log('STABID', data)
  const accordionSections = [
    'Past Medical History',
    'Present Medical History',
    'Thermals',
    'Management',
    'Remarks',
  ]
  const [accordionData, setAccordionData] = useState<AccordionProps[]>([])
  useEffect(() => {
    if (data) {
      let accordionData: any[] = []
      Object.entries(data).map((item: any) => {
        if (accordionSections.indexOf(item[0]) !== -1) {
          accordionData.push({
            heading: item[0],
            text: item[1],
          })
        }
      })
      setAccordionData(accordionData)
    }
  }, [data])

  return (
    <>
      <BannerGeneric
        title={`Details of Patient : ${data['Patient ID']}`}
        description="All you need for cases reference"
      >
        {
          <ul className="actions">
            <li>
              <button onClick={() => history.back()} className="button special">
                Back
              </button>
            </li>
            <li>
              <Link to={`/app/patients`} className="button">
                Patient Data
              </Link>
            </li>
            <li>
              {loggedInUsername === getUsername(data["Doctor's bloomB ID"]) && (
                <RestrictedButton
                  text="Prescription"
                  roles={[BB_DOCTOR]}
                  linkTo={`/app/prescription`}
                  target={true}
                  state={{
                    prescription: data['Rx'],
                    address: `${data.name}, ${data.address}`,
                    descriptionEditable: true,
                    addressEditable: true,
                    consultant: data["Doctor's bloomB ID"],
                    doctorEditable: true,
                  }}
                />
              )}
            </li>
          </ul>
        }
      </BannerGeneric>
      <section id="" className="submitform">
        <div className="inner">
          <div className="content">
            <section id="two">
              <div className="inner">
                <header className="major">
                  <h2>{getUsername(data["Doctor's bloomB ID"])}'s case</h2>
                </header>

                {data.name && (
                  <AccordionDisplay
                    data={[
                      {
                        heading: 'Request Details',
                        subheading: 'Could be MED or APP',
                        text: data['Request ID'],
                      },
                      {
                        heading: 'Patient Details',
                        subheading: data.name,
                        text: `DOB : ${data.dob}`,
                      },
                      {
                        heading: 'Address',
                        subheading: data.mobile,
                        text: `${data.address}`,
                      },
                    ]}
                  />
                )}

                <h3>Chief Complaint</h3>
                <div className="box">
                  <p>{data['Chief Complaint']}</p>
                </div>
                {/* <ImageGridList
                  tileData={[
                    {
                      img:
                        'https://res.cloudinary.com/practicaldev/image/fetch/s--985PBAco--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://drive.google.com/thumbnail%3Fid%3D14hz3ySPn-zBd4Tu3NtY1F05LSGdFfWvp',
                      title: 'Image',
                      author: 'Author',
                      cols: 1,
                    },
                    {
                      img:
                        'https://res.cloudinary.com/practicaldev/image/fetch/s--985PBAco--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://drive.google.com/thumbnail%3Fid%3D14hz3ySPn-zBd4Tu3NtY1F05LSGdFfWvp',
                      title: 'Image',
                      author: 'Author',
                      cols: 2,
                    },
                    {
                      img:
                        'https://res.cloudinary.com/practicaldev/image/fetch/s--985PBAco--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://drive.google.com/thumbnail%3Fid%3D14hz3ySPn-zBd4Tu3NtY1F05LSGdFfWvp',
                      title: 'Image',
                      author: 'Author',
                      cols: 3,
                    },
                  ]}
                /> */}
                {data['Images'] && (
                  <div className="box alt">
                    <div className="grid-wrapper">
                      {data['Images'].split(',').map((img: string) => {
                        const imgUrl = getUrlParam(img, 'id')
                        return (
                          <div
                            className="col-4"
                            style={{ textAlign: 'center' }}
                          >
                            <span className="image fit">
                              <img
                                // src={`https://drive.google.com/thumbnail?id=${imgUrl}`}
                                src={`https://drive.google.com/uc?export=view&id=${imgUrl}`}
                                alt=""
                                // max-height="300"
                                style={{ borderRadius: '10px' }}
                              />
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                <dl>
                  <dt>Associated Complaint</dt>
                  <dd>
                    <p>{data['Associated Complaint']}</p>
                  </dd>
                </dl>
                <h3>Rx</h3>
                <blockquote>{data['Rx']}</blockquote>
              </div>
            </section>

            <section id="login">
              <AccordionDisplay data={accordionData} />
              <Comments
                title={'Patient Case Record'}
                slug={`details/${data['Patient ID']}`}
              />
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Details
