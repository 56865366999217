import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import Requests from '../Requests'
import { BB_REGISTERED_DATE } from '../../utils/constants'

const Appointments: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <BannerGeneric
        title="Medicine-Only Requests"
        description="This is where all medicine-only requests are listed"
      >
        {
          <ul className="actions">
            <li>
              <button onClick={() => history.back()} className="button special">
                Back
              </button>
            </li>
          </ul>
        }
      </BannerGeneric>
      <Requests
        columns={[
          'id',
          'patientId',
          'name',
          'problem',
          'updatedBy',
          BB_REGISTERED_DATE,
        ]}
        table="medicineOnlyRequests"
        tableColumns={[
          'Request ID',
          'Patient ID',
          'Patient Name',
          'Problem',
          'Updated By',
          BB_REGISTERED_DATE,
        ]}
        title="Requests for Medicine"
      />
    </>
  )
}

export default Appointments
