import React from 'react'
import { useMultilevelSnapshotForTable } from './hooks'
import firebase from 'gatsby-plugin-firebase'
import { BB_REGISTERED_DATE } from '../utils/constants'
import { useList } from 'react-firebase-hooks/database'
import Datatable from './Datatable'

const Appointments = () => {
  const [
    appointmentSnapshot,
    isAppointmentsLoading,
    AppointmentsError,
  ] = useList(firebase.database().ref(`appointments`))

  const columns = [
    'id',
    'patientId',
    'name',
    'problem',
    'start_time',
    'type',
    'updatedBy',
    BB_REGISTERED_DATE,
  ]
  const [datatableData] = useMultilevelSnapshotForTable({
    data: appointmentSnapshot,
    columns,
  })
  console.log('TBLAA', datatableData)
  const datatableColumns = [
    'Doctor',
    'Appointment ID',
    'Patient Id',
    'Patient Name',
    'Problem',
    'Start time',
    'Type',
    'Updated By',
    BB_REGISTERED_DATE,
  ]

  return (
    <section
      id=""
      className="submitform"
      style={{ backgroundColor: '#f5f5f5' }}
    >
      <div className="inner">
        <div className="content">
          <section id="login">
            {datatableData && (
              <Datatable
                title={'List of All Appointments'}
                data={datatableData}
                columns={datatableColumns}
              />
            )}
          </section>
        </div>
      </div>
    </section>
  )
}

export default Appointments
