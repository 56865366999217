import React, { useEffect, useState } from 'react'
import {
  useMultiLevelFBRTRef,
  useMultiLevelFilter,
  useSingleLevelFBRTRef,
  useSingleLevelFilter,
} from './hooks'
import firebase from 'gatsby-plugin-firebase'
import { useList } from 'react-firebase-hooks/database'
import AccordionDisplay from './AccordionDisplay'
import WorkflowAction from './WorkflowAction'
import {
  getLoggedInUsername,
  isAdministrator,
  isApprovedRole,
} from '../services/firebase'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { BB_ADMIN, BB_HR, BB_PHARMACIST } from '../utils/constants'
import { detectRequestType } from '../utils'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  statusCodes: number[]
}

interface PanelContentProps {
  item: any
  loggedInUsername: string
}
const PanelContent: React.FC<PanelContentProps> = ({
  item,
  loggedInUsername,
}) => {
  /* Get Request Details */
  let requestType = detectRequestType(item.key)
  const [requestSnapshot, isRequestLoading, requestError] = useList(
    firebase.database().ref(`${requestType}`)
  )
  const [multiLevelData] = useMultiLevelFBRTRef(requestSnapshot)
  console.log('PanelContentProps', multiLevelData)

  const [filteredData] = useMultiLevelFilter({
    data: multiLevelData,
    dataFilter: [
      {
        key: 'id',
        value: item.key,
      },
    ],
  })

  // const [medicineData] = useSingleLevelFBRTRef(multiLevelData)
  // const [medicineFilteredData] = useSingleLevelFilter({
  //   data: medicineData,
  //   dataFilter: {
  //     key: 'id',
  //     value: item.key,
  //   },
  // })

  // console.log('medicineFilteredData', medicineFilteredData)
  /* Get Request Details end here */
  let requestDisplay: string = ''
  if (requestType === 'appointments') {
    requestDisplay = 'APP'
  } else if (requestType === 'medicineOnlyRequests') {
    requestDisplay = 'MED'
  }
  return (
    <AccordionDisplay
      key={item.key}
      data={[
        {
          heading: `${filteredData?.name || item.key} ${
            filteredData?.parentKey ? `(${filteredData?.parentKey}'s case)` : ''
          }`,
          subheading: `${requestDisplay} type`,
        },
      ]}
    >
      <WorkflowAction
        type={item.status}
        data={{ ...item, updatedBy: loggedInUsername }}
      />
    </AccordionDisplay>
  )
}

function TabPanel(props: TabPanelProps) {
  const loggedInUsername = getLoggedInUsername()
  const { statusCodes, children, value, index, ...other } = props

  const [caseStatusSnapshot, isCaseStatusLoading, caseStatusError] = useList(
    firebase.database().ref(`status`)
  )
  const [singleLevelData] = useSingleLevelFBRTRef(caseStatusSnapshot)
  const [filteredData, setFilteredData] = useState<any[]>([])
  console.log('OLAOLA 3', filteredData)
  useEffect(() => {
    if (singleLevelData.length && statusCodes?.length) {
      const filtered = singleLevelData.filter(
        item => statusCodes?.indexOf(item.status) !== -1
      )
      setFilteredData(filtered)
    }
  }, [singleLevelData, statusCodes])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="box alt">
          <div className="grid-wrapper">
            <div className="col-12">
              {filteredData.map(item => {
                return (
                  <PanelContent
                    item={item}
                    loggedInUsername={loggedInUsername}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))
export default function ScrollableTabsButtonAuto() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log('NEW VALUE', event.target)
    setValue(newValue)
  }

  return (
    <section id="blogs" className="major" style={{ backgroundColor: '#fff' }}>
      <div className="inner">
        <div className="content">
          <section id="subscribe">
            <div className="inner">
              <section>
                <div className={classes.root}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab label="Appointments" {...a11yProps(100)} />
                      <Tab label="Consultations" {...a11yProps(1)} />
                      {isApprovedRole([BB_PHARMACIST, BB_ADMIN]) && (
                        <Tab label="Medicines" {...a11yProps(2)} />
                      )}
                      {isApprovedRole([BB_HR, BB_ADMIN]) && (
                        <Tab label="Payment" {...a11yProps(3)} />
                      )}
                      {isApprovedRole([BB_ADMIN]) && (
                        <Tab label="Feedback & Closure" {...a11yProps(4)} />
                      )}
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} statusCodes={[100]}>
                    Item One
                  </TabPanel>
                  <TabPanel value={value} index={1} statusCodes={[105, 58]}>
                    Item Two
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={2}
                    statusCodes={[240, 300, 304]}
                  >
                    Item Three
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={3}
                    statusCodes={[401, 402, 404]}
                  >
                    Item Four
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={4}
                    statusCodes={[410, 505, 508]}
                  >
                    Item Five
                  </TabPanel>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}
