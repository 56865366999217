import { navigate, RouteComponentProps } from '@reach/router'
import React from 'react'
import { CalendlyEventListener, InlineWidget } from 'react-calendly'
import { useList, useListVals } from 'react-firebase-hooks/database'
import {
  getLoggedInUsername,
  setOrUpdateRTdb,
  updateQueue,
} from '../../services/firebase'
import {
  bloomBfetch,
  generateProfileId,
  getDateFromTS,
  getTimestamp,
  getUsername,
  handleWebHook,
  showNotification,
} from '../../utils'
import {
  BB_APPOINTMENT,
  BB_APPOINTMENT_REQUEST,
  BB_DONE_STATUS,
  BB_PENDING_STATUS,
  BB_STATUS_APPOINTMENT_FIXED,
} from '../../utils/constants'
import firebase from 'gatsby-plugin-firebase'
import { Seo } from '../Seo'
import { useSingleLevelFBRTRef } from '../hooks'
import { useSnackbar } from 'notistack'

interface ILocation {
  state: {
    calendly: string
    username: string
    accessToken: string
  }
}

const Schedule: React.FC<RouteComponentProps> = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar()

  console.log(location)
  const loggedInUsername = getLoggedInUsername()

  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )

  const [queueSnapshot, isQueuesLoading, queuesError] = useList(
    firebase.database().ref(`queue`)
  )
  const [queueData] = useSingleLevelFBRTRef(queueSnapshot)

  const doctorsUsername = getUsername(location?.state.username)

  const onEventScheduled = async (e: any) => {
    try {
      // const calendlyData = await bloomBfetch({
      //   uri: e.data.payload.event.uri,
      //   accessToken: location?.state.accessToken,
      // })
      const calendlyData = await bloomBfetch(e.data.payload.event.uri, {
        Authorization: `Bearer ${location?.state.accessToken}`,
      })
      console.log('JSON DATA', calendlyData)
      const {
        resource: {
          created_at,
          location: { join_url, type },
          start_time,
        },
      } = calendlyData
      let object = {
        join_url,
        type,
        start_time,
      }

      // const inviteeData = await bloomBfetch({
      //   uri: e.data.payload.invitee.uri,
      //   accessToken: location?.state.accessToken,
      // })
      const inviteeData = await bloomBfetch(e.data.payload.invitee.uri, {
        Authorization: `Bearer ${location?.state.accessToken}`,
      })
      const {
        resource: { questions_and_answers, name },
      } = inviteeData

      questions_and_answers.map((questionAns: any) => {
        if (questionAns?.question === 'Patient ID') {
          object['patientId'] = questionAns.answer
        } else if (
          questionAns?.question ===
          'Please share anything that will help prepare for our meeting.'
        ) {
          object['problem'] = questionAns.answer
        }
      })

      console.log('FINAL OBJECT', created_at, object)
      const generatedUid = await generateProfileId({
        data: bloomBUidsSnapshot,
        profile: BB_APPOINTMENT,
      })
      await setOrUpdateRTdb({
        ref: `appointments/${doctorsUsername}/${getTimestamp()}`,
        data: {
          ...object,
          name,
          id: generatedUid,
          updatedBy: loggedInUsername,
        },
        executeHook: {
          current: BB_STATUS_APPOINTMENT_FIXED,
        },
      })

      console.log('PATIENT', object)
      await updateQueue({
        data: queueData,
        id: object.patientId,
        type: BB_APPOINTMENT_REQUEST,
        from: BB_PENDING_STATUS,
        to: BB_DONE_STATUS,
      })

      handleWebHook({
        text: `Appointment fixed for ${doctorsUsername}!
        Schedule : ${getDateFromTS(start_time)}`,
        channel: '#general',
      })
      showNotification({
        enqueueSnackbar,
        text: 'Appointment Scheduled! Please Verify this list',
        type: 'success',
      })
      // dispatch({
      //   type: 'MESSAGE',
      //   payload: {
      //     type: 'success',
      //     text: 'New Patient Registration Successful!',
      //   },
      // })
      navigate('/app/appointments')
    } catch (err) {
      console.log(err)
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }
  return (
    <>
      <Seo title="Dashboard" />
      {/* <BannerGeneric
        title="Schedule Appointment"
        description="This is where you can schedule an appointment"
      ></BannerGeneric> */}
      <InlineWidget url={location?.state.calendly} />
      <CalendlyEventListener onEventScheduled={onEventScheduled} />
    </>
  )
}

export default Schedule
