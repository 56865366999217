import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { getDateFromTS } from '../utils'
import { useList, useObjectVal } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { useSingleLevelFBRTRef } from './hooks'
import CompleteWorkflow from './CompleteWorkflow'
const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '60px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface SearchTimelineProps {
  requestId: string
}
const SearchTimeline: React.FC<SearchTimelineProps> = ({ requestId }) => {
  console.log('REQ', requestId)
  const classes = useStyles()
  const [
    workflowStatusSnapshot,
    isWorkflowStatussLoading,
    workflowStatussError,
  ] = useObjectVal(firebase.database().ref(`workflowLevels`))
  const [workflowLevels, setWorkflowLevels] = useState([])
  console.log('STATUS 10000', workflowLevels)
  useEffect(() => {
    if (workflowStatusSnapshot) {
      const workflowLevelsArray = Object.entries(workflowStatusSnapshot)
      setWorkflowLevels(workflowLevelsArray)
    }
  }, [workflowStatusSnapshot])
  const [caseStatusSnapshot, isCaseStatussLoading, caseStatussError] = useList(
    firebase.database().ref(`workflow/${requestId}`)
  )
  console.log('STATUS ABID', caseStatusSnapshot)
  const [singleLevelData] = useSingleLevelFBRTRef(caseStatusSnapshot)
  console.log('STATUS ABID 2', singleLevelData)
  const [display, setDisplay] = useState(false)
  const [statusCodes, setStatusCodes] = useState([])
  useEffect(() => {
    if (singleLevelData.length && requestId && caseStatusSnapshot.length) {
      setDisplay(true)
      let statusArry = []
      singleLevelData.map(item => statusArry.push(item.status))
      setStatusCodes(statusArry)
    } else {
      setDisplay(false)
    }
  }, [singleLevelData])

  return (
    <>
      {requestId && singleLevelData && (
        <Timeline align="alternate" className={classes.root}>
          {singleLevelData.map(item => {
            return (
              <TimelineItem key={item.key}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {getDateFromTS(Number(item['key']))}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary"></TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {workflowStatusSnapshot[item['status']]}
                    </Typography>
                    {/* <Typography>
                                      Discuss your problem
                                    </Typography> */}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      )}
      <CompleteWorkflow statusCodes={statusCodes} />
    </>
  )
}

export default SearchTimeline
