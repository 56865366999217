import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchTimeline from './SearchTimeline'
import Search from './Search'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '60px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))
const Status = () => {
  const classes = useStyles()

  //   const stages = [
  //     'Appointment Fixed',
  //     'Medicine-only Request Initiated',
  //     'No Show',
  //     'Consultation Complete',
  //     'Recorded Case History',
  //     'Prescription generated by Doctor',
  //     'Invoice generated by Pharmacist',
  //     'Medicine Dispensed & Despatched',
  //     'Pharmacy prescription uploaded',
  //     'Payment Link generated',
  //     'Payment Due informed',
  //     'Payment deferred',
  //     'Payment Complete',
  //     'Updated Tracking status to patient',
  //     'Requested feedback from patient',
  //     'Feedback received',
  //     'Case Closed',
  //   ]

  const [searchText, setSearchText] = useState('')

  return (
    <>
      <section id="blogs" className="major" style={{ backgroundColor: '#fff' }}>
        <div className="inner">
          <div className="content">
            <section id="subscribe">
              <div className="inner">
                <section>
                  <div className="box alt">
                    <div className="grid-wrapper">
                      <div className="col-12">
                        <Search
                          searchText={searchText}
                          setSearchText={setSearchText}
                          prefix={true}
                        />
                        <br />
                        <br />
                        <SearchTimeline requestId={searchText} />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export default Status
