import { createMachine } from 'xstate'
import { handleStatusHook } from '../../services/firebase'
import {
  BB_STATUS_CASE_CLOSED,
  BB_STATUS_FEEDBACK_RECEIVED,
  BB_STATUS_FEEDBACK_REQUESTED,
} from '../../utils/constants'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'feedback'; context: { data: any } }
  | { value: 'requested'; context: { data: any } }
  | { value: 'received'; context: { data: any } }
  | { value: 'closed'; context: { data: any } }

const logMsg = () => console.log('Creating & Requesting...')

export const feedbackMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'recordWorkflows',
  initial: 'feedback',
  states: {
    feedback: {
      on: {
        REQUESTED: 'requested',
        RECEIVED: 'received',
        CLOSED: 'closed',
      },
    },
    requested: {
      invoke: {
        id: 'updateRequested',
        src: (context, event) => {
          console.log(
            'XSTATE Creating & Requesting updateRequested...',
            context
          )
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_FEEDBACK_REQUESTED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    received: {
      invoke: {
        id: 'updateReceived',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateReceived...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_FEEDBACK_RECEIVED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    closed: {
      invoke: {
        id: 'updateClosed',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateClosed...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_CASE_CLOSED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      type: 'final',
    },
  },
})
