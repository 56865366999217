import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { getNameAddress, getUsername, sortData, trimText } from '../utils'
import RestrictedButton from './RestrictedButton'
import {
  BB_ADMIN,
  BB_ADMIN_USERNAME,
  BB_CASE_HISTORY_GOOGLESHEET_ID,
  BB_DOCTOR,
} from '../utils/constants'
import { useGoogleSheet, useLoggedInUser, useSingleLevelFBRTRef } from './hooks'
import { useList } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import { Link } from 'gatsby'
import AlertMsg from './AlertMsg'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default function OutlinedCard({
  sheetId,
  text = '',
}: {
  sheetId: string
  text?: string
}) {
  console.log('SRCHHH', text)
  const [loggedInUsername] = useLoggedInUser()
  const [googleData] = useGoogleSheet(sheetId)

  const [googleSheetData, setGoogleSheetData] = useState([])
  console.log('googleSheetData', googleSheetData)
  useEffect(() => {
    if (googleData.length) {
      const filtered = googleData.filter(
        item =>
          (item['Associated Complaint'] &&
            item['Associated Complaint'].toLowerCase().search(text) !== -1) ||
          (item['Chief Complaint'] &&
            item['Chief Complaint'].toLowerCase().search(text) !== -1) ||
          (item["Doctor's bloomB ID"] &&
            item["Doctor's bloomB ID"].toLowerCase().search(text) !== -1) ||
          (item['Patient ID'] &&
            item['Patient ID'].toLowerCase().search(text) !== -1) ||
          (item['Request ID'] &&
            item['Request ID'].toLowerCase().search(text) !== -1) ||
          (item['Rx'] && item['Rx'].toLowerCase().search(text) !== -1) ||
          (item['invoiceNumber'] &&
            item['invoiceNumber'].toLowerCase().search(text) !== -1) ||
          (item['Diagnosis'] &&
            item['Diagnosis'].toLowerCase().search(text) !== -1)
      )
      console.log('FILTT before', filtered)

      const sortedData = sortData(filtered)
      setGoogleSheetData(sortedData)
    }
  }, [googleData, text])

  const [patientsSnapshot, isPatientsLoading, patientsError] = useList(
    firebase.database().ref(`patients`)
  )
  const [patientsData] = useSingleLevelFBRTRef(patientsSnapshot)

  const classes = useStyles()
  // const bull = <span className={classes.bullet}>•</span>

  return (
    <>
      <section>
        <AlertMsg
          type="info"
          text="No more scrolling down! Latest case will appear on top"
        />
        <div className="box alt">
          <div className="grid-wrapper">
            {googleSheetData.length ? (
              googleSheetData.map((rowData: any) => {
                console.time('ABID')
                const filteredData = getNameAddress({
                  data: patientsData,
                  dataFilter: {
                    key: 'id',
                    value: rowData['Patient ID'],
                  },
                })
                let newRowData = { ...rowData }
                if (filteredData) {
                  newRowData = {
                    ...newRowData,
                    name: filteredData.name,
                    address: filteredData.address,
                    dob: filteredData.dob,
                    mobile: filteredData.mobile,
                  }
                }
                console.timeEnd('ABID')

                console.log('STABID', newRowData)
                return (
                  <div className="col-4" key={newRowData['Timestamp']}>
                    <Card style={{ margin: '10px 0' }}>
                      <CardContent>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          Patient ID : {newRowData['Patient ID']}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ color: '#000' }}
                        >
                          {/* be{bull}nev{bull}o{bull}lent */}
                          {trimText(newRowData['Chief Complaint'], 20)}
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          {getUsername(newRowData["Doctor's bloomB ID"])}'s Case
                          <br />
                          <sup>( {newRowData['Timestamp']} )</sup>
                        </Typography>
                        {newRowData['Diagnosis'] && (
                          <Typography variant="body2" component="p">
                            {trimText(newRowData['Associated Complaint'], 30)}
                            <br />
                            Diagnosis : {trimText(newRowData['Diagnosis'], 20)}
                          </Typography>
                        )}
                        <br />
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ height: '80px' }}
                        >
                          Rx : {trimText(newRowData['Rx'], 70)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          state={{
                            data: newRowData,
                            loggedInUsername,
                          }}
                          className="button"
                          style={{ color: '#000' }}
                          to={`/app/details`}
                        >
                          Learn more
                        </Link>
                        {/* <RestrictedButton
                        text="Learn More"
                        roles={[BB_DOCTOR]}
                        linkTo={`/app/details`}
                        target={true}
                        state={{
                          data: newRowData,
                          loggedInUsername,
                        }}
                      /> */}
                        {(loggedInUsername ===
                          getUsername(newRowData["Doctor's bloomB ID"]) ||
                          loggedInUsername === BB_ADMIN_USERNAME) && (
                          <RestrictedButton
                            text="Prescription"
                            roles={[BB_ADMIN]}
                            linkTo={`/app/prescription`}
                            target={true}
                            state={{
                              googleData: newRowData,
                              descriptionEditable: true,
                              addressEditable: true,
                              doctorEditable: true,
                              imadoctor: true,
                            }}
                          />
                        )}
                      </CardActions>
                    </Card>
                  </div>
                )
              })
            ) : (
              <div className="col-12">
                <p>No Cases to display based on your search!</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
