import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

interface DatatableProps {
  title: string
  data: string[][]
  columns: string[]
}
const Datatable: React.FC<DatatableProps> = ({ title, data, columns }) => {
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          tableRoot: {
            // backgroundColor: '#6fc3df',
            opacity: '0.8',
          },
        },
      },
    })
  const options = {
    filterType: 'checkbox',
  }
  /*const data = [
    ['Joe James', 'Test Corp', 'Yonkers', 'NY'],
    ['John Walsh', 'Test Corp', 'Hartford', 'CT'],
    ['Bob Herm', 'Test Corp', 'Tampa', 'FL'],
    ['James Houston', 'Test Corp', 'Dallas', 'TX'],
  ]*/
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable title={title} data={data} columns={columns} options={{}} />
    </MuiThemeProvider>
  )
}

export default Datatable
