import firebase from 'gatsby-plugin-firebase'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useList, useListVals } from 'react-firebase-hooks/database'
import invoice from '../assets/images/invoice.png'
import { getLoggedInUsername, setOrUpdateRTdb } from '../services/firebase'
import {
  generateProfileId,
  getTimestamp,
  handleWebHook,
  showNotification,
  todaysDate,
} from '../utils'
import {
  BB_INVOICE,
  BB_STATUS_PRESCRIPTION_GEN_BY_DOCTOR,
  BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST,
} from '../utils/constants'
import { useMultiLevelFBRTRef, useMultiLevelFilter } from './hooks'
import UpdateButton from './UpdateButton'

interface PrescriptionProps {
  data: {
    googleData: {
      [key: string]: string
    }
    prescription: string
    address: string
    price: string
    amount: string
    delivery: string
    total: string
    consultant: string
    amountEditable: boolean
    descriptionEditable: boolean
    addressEditable: boolean
    priceEditable: boolean
    deliveryEditable: boolean
    totalEditable: boolean
    doctorEditable: boolean
    generateInvoice?: boolean
    invoiceNumber?: string
    invoiceDate?: string
    imadoctor?: boolean
  }
}

const Prescription: React.FC<PrescriptionProps> = ({ data }) => {
  const {
    googleData,
    price,
    amount,
    delivery,
    total,
    amountEditable = false,
    descriptionEditable = false,
    addressEditable = false,
    priceEditable = false,
    deliveryEditable = false,
    totalEditable = false,
    doctorEditable = false,
    generateInvoice = false,
    imadoctor = false,
    invoiceNumber,
    invoiceDate,
  } = data
  console.log('UNDA CASEHIST GGL', data)
  const loggedInUsername = getLoggedInUsername()

  const [updateForEdit, setUpdateForEdit] = useState<boolean>()
  const [updatedData, setUpdatedData] = useState(googleData)

  const { enqueueSnackbar } = useSnackbar()

  const [bloomBUidsSnapshot, isBloomBUidsLoading] = useListVals(
    firebase.database().ref(`uids`)
  )

  console.log('UPD', updatedData)
  const [showUpdateButton, setShowUpdateButton] = useState(true)
  console.log('SHOW IT', showUpdateButton)
  const handleEdit = (e: any) => {
    setUpdateForEdit(true)
    // setUpdatedData({ ...updatedData, [e.target.id]: e.target.textContent })
  }

  const [
    caseHistorySnapshot,
    isCaseHistorysLoading,
    caseHistorysError,
  ] = useList(firebase.database().ref(`casehistory`))
  console.log('UNDA CADEHISTR', caseHistorySnapshot)

  const [multiLevelData] = useMultiLevelFBRTRef(caseHistorySnapshot)
  console.log('UNDA CASEHIST FINAL', multiLevelData)
  const [filteredData] = useMultiLevelFilter({
    data: multiLevelData,
    dataFilter: [
      {
        key: 'Patient ID',
        value: googleData['Patient ID'],
      },
      {
        key: 'Request ID',
        value: googleData['Request ID'],
      },
    ],
  })
  console.log('UNDA UPD CASE HIST FILTERED FINAL', filteredData)
  useEffect(() => {
    if (filteredData && filteredData.hasOwnProperty('Patient ID')) {
      setUpdatedData({ ...filteredData })
    }
  }, [filteredData])

  const prescriptionRef = useRef()
  const addressRef = useRef()
  const doctorRef = useRef()
  const priceRef = useRef()
  const amountRef = useRef()
  const deliveryRef = useRef()
  const totalRef = useRef()
  const invoiceRef = useRef()
  const dateRef = useRef()

  const handleUpdate = async () => {
    try {
      console.log('updating....', prescriptionRef.current)
      const prescriptionData = prescriptionRef?.current.querySelectorAll(
        'ul li'
      )
      let prescriptionDataArray = []
      let prescriptionText
      for (let i = 0; i < prescriptionData.length; i++) {
        prescriptionDataArray.push(prescriptionData[i].textContent)
      }
      if (prescriptionDataArray.length) {
        prescriptionText = prescriptionDataArray.join(', ')
      }

      const addressData = addressRef?.current.querySelectorAll('ul li')
      let addressDataArray = []
      let addressText
      for (let i = 0; i < addressData.length; i++) {
        addressDataArray.push(addressData[i].textContent)
      }
      if (addressDataArray.length) {
        addressText = addressDataArray.join(', ')
      }
      let priceText = ''
      let amountText = '',
        deliveryAmount = '',
        totalAmount = '',
        invoiceNumber = '',
        invoiceDate = '',
        statusCode
      if (!imadoctor) {
        statusCode = BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST
        handleWebHook({
          text: `about to invoice the pharmacy prescription`,
          channel: '#general',
        })
        const priceData = priceRef?.current.querySelectorAll('ul li')
        let priceDataArray = []

        for (let i = 0; i < priceData.length; i++) {
          priceDataArray.push(priceData[i].textContent)
        }
        if (priceDataArray.length) {
          priceText = priceDataArray.join(', ')
        }

        const amountData = amountRef?.current.querySelectorAll('ul li')
        let amountDataArray = []

        for (let i = 0; i < amountData.length; i++) {
          amountDataArray.push(amountData[i].textContent)
        }
        if (amountDataArray.length) {
          amountText = amountDataArray.join(', ')
        }
        deliveryAmount = deliveryRef?.current.textContent
        totalAmount = totalRef?.current.textContent
        invoiceNumber = invoiceRef?.current.textContent
        invoiceDate = dateRef?.current.textContent
      } else {
        statusCode = BB_STATUS_PRESCRIPTION_GEN_BY_DOCTOR

        handleWebHook({
          text: `about to finalize the Doctor's prescription`,
          channel: '#general',
        })
      }
      // if data exists in fb rt table
      if (filteredData && Object.entries(filteredData).length) {
        const parentKey = filteredData['parentKey']
        const requestId = filteredData['Request ID']
        const nestedKey = filteredData['key']
        delete filteredData['parentKey']
        delete filteredData['key']
        await setOrUpdateRTdb({
          ref: `casehistory/${parentKey}/${nestedKey}`,
          data: {
            ...filteredData,
            Rx: prescriptionText,
            address: addressText,
            ["Doctor's bloomB ID"]: doctorRef.current.textContent,
            price: priceText,
            amount: amountText,
            delivery: deliveryAmount,
            total: totalAmount,
            invoiceNumber: generateInvoice ? invoiceNumber : '',
            invoiceDate: generateInvoice ? invoiceDate : '',
            updatedBy: loggedInUsername,
            id: requestId,
          },
          executeHook: {
            current: statusCode,
          },
        })
        showNotification({
          enqueueSnackbar,
          text:
            'Updated! Prescription can now be taken up for upload & payment',
          type: 'success',
        })
      } else {
        // fresh data from google
        await setOrUpdateRTdb({
          ref: `casehistory/${googleData['Patient ID']}/${getTimestamp()}`,
          data: {
            ...updatedData,
            Rx: prescriptionText,
            address: addressText,
            ["Doctor's bloomB ID"]: doctorRef.current.textContent,
            price: priceText,
            amount: amountText,
            delivery: deliveryAmount,
            total: totalAmount,
            invoiceNumber: generateInvoice ? invoiceNumber : '',
            invoiceDate: generateInvoice ? invoiceDate : '',
            updatedBy: loggedInUsername,
            id: googleData['Request ID'],
          },
          executeHook: {
            current: statusCode,
          },
        })
        showNotification({
          enqueueSnackbar,
          text:
            'Case Details Updated! Prescription can now be updated by Pharmacist',
          type: 'success',
        })
      }
      setShowUpdateButton(false)
    } catch (err) {
      console.log(err)
      showNotification({
        enqueueSnackbar,
        text: err.message,
        type: 'error',
      })
    }
  }
  const [generatedInvoice, setGeneratedInvoice] = useState('')
  useEffect(() => {
    const gen = async () => {
      const generatedInvoice = await generateProfileId({
        data: bloomBUidsSnapshot,
        profile: BB_INVOICE,
      })
      setGeneratedInvoice(generatedInvoice)
    }
    if (!invoiceNumber) {
      gen()
    }
  }, [invoiceNumber])

  return (
    <section>
      <div className="inner">
        <div className="content">
          <section className="prescription">
            <img src={invoice} width="100%" />
            <div
              ref={prescriptionRef}
              className="centered-description"
              contentEditable={descriptionEditable}
              onInput={handleEdit}
            >
              <ul className="alt">
                {updatedData['Rx']
                  .split(',')
                  .map((item: string, index: number) => {
                    return (
                      <li key={index} className={!imadoctor ? 'online' : ''}>
                        {item}
                      </li>
                    )
                  })}
              </ul>
            </div>
            {(updatedData['price'] || priceEditable) && (
              <div
                className="centered-price"
                contentEditable={priceEditable}
                onInput={handleEdit}
                ref={priceRef}
              >
                {!updatedData['price'] ? (
                  <ul className="alt">
                    {updatedData['Rx']
                      .split(',')
                      .map((item: string, index: number) => {
                        return (
                          <li
                            key={index}
                            className={!imadoctor ? 'online' : ''}
                          ></li>
                        )
                      })}
                  </ul>
                ) : (
                  <ul className="alt">
                    {updatedData['price']
                      .split(',')
                      .map((item: string, index: number) => {
                        return (
                          <li
                            key={index}
                            className={!imadoctor ? 'online' : ''}
                          >
                            {item}
                          </li>
                        )
                      })}
                  </ul>
                )}
              </div>
            )}
            {(updatedData['amount'] || amountEditable) && (
              <div
                className="centered-amount"
                contentEditable={amountEditable}
                onInput={handleEdit}
                ref={amountRef}
              >
                {!updatedData['price'] ? (
                  <ul className="alt">
                    {updatedData['Rx']
                      .split(',')
                      .map((item: string, index: number) => {
                        return (
                          <li
                            key={index}
                            className={!imadoctor ? 'online' : ''}
                          ></li>
                        )
                      })}
                  </ul>
                ) : (
                  <ul className="alt">
                    {updatedData['amount']
                      .split(',')
                      .map((item: string, index: number) => {
                        return (
                          <li
                            key={index}
                            className={!imadoctor ? 'online' : ''}
                          >
                            {item}
                          </li>
                        )
                      })}
                  </ul>
                )}
              </div>
            )}
            <div className="top-name">{updatedData['name']}</div>
            <div
              ref={addressRef}
              className="top-address"
              contentEditable={addressEditable}
              onInput={handleEdit}
            >
              <ul className="alt" style={{ listStyle: ' none' }}>
                {updatedData['address'] &&
                  updatedData['address']
                    .split(',')
                    .map((item: string, index: number) => {
                      return (
                        <li key={index} style={{ padding: '0' }}>
                          {item}
                        </li>
                      )
                    })}
              </ul>
            </div>
            {(generateInvoice || invoiceNumber) && (
              <div className="top-invoice" ref={invoiceRef}>
                {`${invoiceNumber || generatedInvoice}`}
              </div>
            )}
            {(generateInvoice || invoiceDate) && (
              <div className="top-date" ref={dateRef}>{`${invoiceDate ||
                todaysDate()}`}</div>
            )}
            <div
              className="top-consultant"
              contentEditable={doctorEditable}
              onInput={handleEdit}
              ref={doctorRef}
            >
              {updatedData["Doctor's bloomB ID"]}
            </div>
            {(updatedData.delivery || deliveryEditable) && (
              <div className="bottom-delivery">
                Rs.&nbsp;
                <span
                  contentEditable={deliveryEditable}
                  onInput={handleEdit}
                  ref={deliveryRef}
                >
                  {updatedData.delivery ? updatedData.delivery : '...'}
                </span>
              </div>
            )}
            {(updatedData.total || totalEditable) && (
              <div className="bottom-total">
                Rs.{' '}
                <span
                  contentEditable={totalEditable}
                  onInput={handleEdit}
                  ref={totalRef}
                >
                  {updatedData.total ? updatedData.total : '...'}
                </span>
              </div>
            )}

            {showUpdateButton && (
              <div className="action-update">
                <UpdateButton handleUpdate={handleUpdate} />
              </div>
            )}
          </section>
        </div>
      </div>
    </section>
  )
}

export default Prescription
