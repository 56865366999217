import React, { useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Link } from 'gatsby'
import { requestPasswordReset } from '../../services/firebase'
import { useMessage } from '../hooks'
import Message from '../Message'

const Register: React.FC<RouteComponentProps> = () => {
  const emailAddress = useRef<any | null>(null)
  const [msg, setMsg] = useMessage()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    requestPasswordReset({
      emailAddress: emailAddress.current.value,
    })
      .then(user => {
        setMsg({
          ...msg,
          type: 'success',
          text: 'Email Sent! Please check your inbox.',
        })
      })
      .catch(function(error: any) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // ...
        console.log(errorCode, errorMessage)
        setMsg({ type: 'error', text: errorMessage })
      })
  }
  const handleChange = (e: any) => {}

  return (
    <section id="banner" className="submitform">
      <div className="inner">
        <header className="major">
          <h1>Forgot Password?</h1>
        </header>
        <div className="content">
          <section id="forgotPassword">
            <div className="inner">
              <section>
                <form method="post" action="#" onSubmit={handleSubmit}>
                  <div className="grid-wrapper">
                    <div className="col-6">
                      <div className="mb-5">
                        <input
                          ref={emailAddress}
                          type="email"
                          name="retrieve-from-email"
                          id="retrieve-from-email"
                          required
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-5">
                        <input
                          type="submit"
                          value="Request Reset"
                          className="special"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="field half first">
                  {msg && msg.text && (
                    <Message type={msg.type} message={msg.text} />
                  )}
                  <Link to="/app/login">
                    <i>Click here to Sign In</i>
                  </Link>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Register
