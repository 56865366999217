import React, { useState } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import Cases from '../Cases'
import BannerGeneric from '../BannerGeneric'
import { useSlack } from '../hooks'
import RestrictedButton from '../RestrictedButton'
import {
  BB_CASE_FOLLOWUP_FORM,
  BB_CASE_FOLLOWUP_GOOGLESHEET_ID,
  BB_CASE_HISTORY_GOOGLESHEET_ID,
  BB_DOCTOR,
} from '../../utils/constants'
import Search from '../Search'

const Registration: React.FC<RouteComponentProps> = () => {
  // const [display] = useApprovedContent([BB_DOCTOR])
  useSlack('Cases')
  const [searchText, setSearchText] = useState('')
  return (
    <>
      <>
        <BannerGeneric
          title="Follow-up Case Records"
          description="All Follow-ups will appear here"
        >
          {
            <ul className="actions">
              <li>
                <button
                  onClick={() => history.back()}
                  className="button special"
                >
                  Back
                </button>
              </li>
              <li>
                <Link
                  to={`/app/cases`}
                  className="button"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </Link>
              </li>
              <li>
                <RestrictedButton
                  text="Case Follow-up Form"
                  roles={[BB_DOCTOR]}
                  linkTo={BB_CASE_FOLLOWUP_FORM}
                  target={true}
                />
              </li>
            </ul>
          }
        </BannerGeneric>
        <section id="" className="submitform">
          <div className="inner">
            <div className="content">
              <section
                style={{
                  padding: '10px',
                  backgroundColor: '#fff',
                  borderRadius: '20px',
                  marginBottom: '20px',
                }}
              >
                <Search searchText={searchText} setSearchText={setSearchText} />
              </section>
              <section id="login">
                <Cases
                  sheetId={BB_CASE_FOLLOWUP_GOOGLESHEET_ID}
                  text={searchText.toLowerCase()}
                />
              </section>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default Registration
