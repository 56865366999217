import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { getUrlParam, getUsername, trimText } from '../utils'
import RestrictedButton from './RestrictedButton'
import {
  BB_ADMIN,
  BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID,
} from '../utils/constants'
import { useGoogleSheet, useLoggedInUser } from './hooks'
import ShowModal from './ShowModal'
import { isApprovedRole } from '../services/firebase'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default function OutlinedCard() {
  // const [loggedInUsername] = useLoggedInUser()

  const [googleData] = useGoogleSheet(BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID)
  console.log('CRYPS', googleData)

  // const [patientsSnapshot, isPatientsLoading, patientsError] = useList(
  //   firebase.database().ref(`patients`)
  // )
  // const [patientsData] = useSingleLevelFBRTRef(patientsSnapshot)

  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  return (
    <>
      <section>
        <div className="box alt">
          <div className="grid-wrapper">
            {googleData.map((newRowData: any) => {
              // const filteredData = getNameAddress({
              //   data: patientsData,
              //   dataFilter: {
              //     key: 'id',
              //     value: rowData['Patient ID'],
              //   },
              // })
              // let newRowData = { ...rowData }
              // if (filteredData) {
              //   newRowData = {
              //     ...newRowData,
              //     name: filteredData.name,
              //     address: filteredData.address,
              //     dob: filteredData.dob,
              //     mobile: filteredData.mobile,
              //   }
              // }
              console.log('STABID', newRowData)
              const imgUrl = getUrlParam(
                newRowData['Clinic Prescription'],
                'id'
              )
              return (
                <div className="col-4" key={newRowData['Timestamp']}>
                  <Card
                    style={{
                      margin: '10px 0',
                      backgroundImage: `url(https://drive.google.com/thumbnail?id=${imgUrl})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Request ID : {newRowData['Request ID']}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ color: '#000' }}
                      >
                        {/* be{bull}nev{bull}o{bull}lent */}
                        Rs. {newRowData['Total Medicines Cost']}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {getUsername(newRowData["Pharmacist's bloomB ID"])}'s
                        Medicine
                      </Typography>
                      <Typography variant="body2" component="p">
                        Updated On : {newRowData['Timestamp']}
                      </Typography>
                      <br />
                      <Typography
                        variant="body2"
                        component="p"
                        style={{ height: '80px' }}
                      >
                        Remarks : {trimText(newRowData['Remarks'], 70)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <ShowModal
                        buttonLabel="View"
                        title={`${getUsername(
                          newRowData["Pharmacist's bloomB ID"]
                        )}'s Prescription`}
                      >
                        <img
                          src={`https://drive.google.com/uc?export=view&id=${imgUrl}`}
                          width="100%"
                        />
                      </ShowModal>

                      {isApprovedRole([BB_ADMIN]) && (
                        <RestrictedButton
                          text="Invoice"
                          roles={[BB_ADMIN]}
                          linkTo={`/app/payment`}
                          target={true}
                          state={{
                            data: newRowData,
                          }}
                        />
                      )}
                      {isApprovedRole([BB_ADMIN]) && (
                        <RestrictedButton
                          text="Final Receipt"
                          roles={[BB_ADMIN]}
                          linkTo={`/app/payment`}
                          target={true}
                          state={{
                            data: newRowData,
                          }}
                        />
                      )}
                    </CardActions>
                  </Card>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}
