import { Link } from 'gatsby'
import React from 'react'
import pic06 from '../assets/images/pic06.jpg'

interface TileProps {
  title: string
  subtitle?: string
  linkTo: string
}
const Tile: React.FC<TileProps> = ({ title, subtitle, linkTo }) => {
  return (
    <article style={{ backgroundImage: `url(${pic06})` }}>
      <header className="major">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </header>
      <Link to={linkTo} className="link primary"></Link>
    </article>
  )
}

export default Tile
