import React, { useEffect, useState } from 'react'
import { useSnapShotForTable } from './hooks'
import firebase from 'gatsby-plugin-firebase'
import { useList } from 'react-firebase-hooks/database'
import Datatable from './Datatable'

interface RequestsProps {
  filter?: {
    type: string
    status: string
  }
  columns: string[]
  table: string
  title: string
  tableColumns: string[]
}
const Requests: React.FC<RequestsProps> = ({
  filter,
  columns,
  table,
  title,
  tableColumns,
}) => {
  const [queuesSnapshot, isQueuesLoading, queuseError] = useList(
    firebase.database().ref(table)
  )
  console.log('REQQQ', queuesSnapshot)
  // const columns = [
  //   'patientId',
  //   'requestType',
  //   'status',
  //   'updatedBy',
  //   BB_REGISTERED_DATE,
  // ]
  const [datatableData] = useSnapShotForTable({
    data: queuesSnapshot,
    columns,
  })

  const [filteredData, setFilteredData] = useState<any[]>([])
  useEffect(() => {
    if (datatableData?.length && filter) {
      const filtered = datatableData.filter(
        item => item[2] === filter?.status && item[1] === filter?.type
      )
      setFilteredData(filtered)
    }
  }, [datatableData])

  return (
    <section
      id=""
      className="submitform"
      style={{ backgroundColor: '#f5f5f5' }}
    >
      <div className="inner">
        <div className="content">
          <section id="login">
            {(datatableData || filteredData) && (
              <Datatable
                title={title}
                data={filter ? filteredData : datatableData}
                columns={tableColumns}
              />
            )}
          </section>
        </div>
      </div>
    </section>
  )
}

export default Requests
