import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AccordionProps } from '../utils/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      fontWeight: theme.typography.fontWeightRegular,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
)

export default function SimpleAccordion({
  data,
  children,
}: {
  data: AccordionProps[]
  children?: React.ReactNode
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {data?.map((item: any, index: number) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Typography className={classes.heading}>
                {item.heading}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {item.subheading}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.text ? <blockquote>{item.text}</blockquote> : children}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}
