import { createMachine } from 'xstate'
import { handleStatusHook } from '../../services/firebase'
import {
  BB_STATUS_PAYMENT_DUE_INFORMED,
  BB_STATUS_PAYMENT_DEFERRED,
  BB_STATUS_PAYMENT_COMPLETE,
} from '../../utils/constants'

type workflowEvents = { type: 'NEXT' }

type workflowStates =
  | { value: 'payment'; context: { data: any } }
  | { value: 'informed'; context: { data: any } }
  | { value: 'deferred'; context: { data: any } }
  | { value: 'complete'; context: { data: any } }

const logMsg = () => console.log('Creating & Requesting...')

export const paymentMachine = createMachine<
  undefined,
  workflowEvents,
  workflowStates
>({
  id: 'recordWorkflows',
  initial: 'payment',
  states: {
    payment: {
      on: {
        INFORMED: 'informed',
        DEFFERED: 'deferred',
        COMPLETE: 'complete',
      },
    },
    informed: {
      invoke: {
        id: 'updateInformed',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateInformed...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_PAYMENT_DUE_INFORMED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    deferred: {
      invoke: {
        id: 'updateDeferred',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateDeferred...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_PAYMENT_DEFERRED)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    complete: {
      invoke: {
        id: 'updateComplete',
        src: (context, event) => {
          console.log('XSTATE Creating & Requesting updateComplete...', context)
          const data = { ...context?.data, id: context?.data.key }
          handleStatusHook(data, BB_STATUS_PAYMENT_COMPLETE)
        },
        onDone: {
          target: 'log',
        },
      },
    },
    log: {
      type: 'final',
    },
  },
})
