import React, { useEffect, useState } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AlertDialogSlide from './Confirmation'
import {
  BB_ADMIN_USERNAME,
  BB_APPOINTMENT_REQUEST_PREFIX,
  BB_MEDICINEONLY_REQUEST_PREFIX,
} from '../utils/constants'
import { useList } from 'react-firebase-hooks/database'
import firebase from 'gatsby-plugin-firebase'
import {
  useAuthor,
  useLoggedInUser,
  useMultiLevelFBRTRef,
  useMultiLevelFilter,
} from './hooks'
import Message from './Message'
import { isAdministrator } from '../services/firebase'
import { detectRequestType } from '../utils'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '60px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface WorkflowTimelineProps {
  data: {
    button: string
    text?: string
    action: any
  }[]
  requestId: string
  current: any
  stage: string
}
const WorkflowTimeline: React.FC<WorkflowTimelineProps> = ({
  data,
  current,
  stage,
  requestId,
}) => {
  const [loggedInUsername] = useLoggedInUser()

  console.log('DOCC', requestId)
  let requestType = detectRequestType(requestId)
  const [requestSnapshot, isRequestLoading, requestError] = useList(
    firebase.database().ref(`${requestType}`)
  )
  const [multiLevelData] = useMultiLevelFBRTRef(requestSnapshot)
  const [filteredData] = useMultiLevelFilter({
    data: multiLevelData,
    dataFilter: [
      {
        key: 'id',
        value: requestId,
      },
    ],
  })
  console.log('AUTHOR ABID', filteredData)
  const [author] = useAuthor(filteredData, requestType)
  console.log('HUU', author)
  const classes = useStyles()
  const [modal, setModal] = useState({ show: false })
  return (
    <>
      {current.matches(stage) ? (
        <>
          <Timeline align="alternate" className={classes.root}>
            {data.map((item, index) => {
              return (
                <TimelineItem key={`timeline_${index}`}>
                  <TimelineOppositeContent>
                    {/* <Typography variant="body2" color="textSecondary">
            9:30 am
          </Typography> */}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      {/* <FastfoodIcon /> */}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() =>
                          setModal({ show: true, action: item.action })
                        }
                      >
                        {item.button}
                      </Button>

                      <Typography>{item.text}</Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              )
            })}
            {modal.show && loggedInUsername !== author && !isAdministrator() && (
              <div style={{ fontSize: '1rem' }}>
                <Message
                  type={`error`}
                  message={'UNAUTHORIZED! YOU ARE NOT ALLOWED'}
                />
              </div>
            )}
          </Timeline>
        </>
      ) : (
        <Timeline align="alternate" className={classes.root}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary"></TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography>
                  This case has been moved from {stage} stage
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}
      {modal.show && (loggedInUsername === author || isAdministrator()) && (
        <AlertDialogSlide show={modal} setShow={setModal} />
      )}
      {/* {modal.show && loggedInUsername !== author && (
        <>
          <br />
          <div>
            <Message type={`error`} message={'Unauthorized Attempt'} />
          </div>
        </>
      )} */}
    </>
  )
}

export default WorkflowTimeline
