import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import PrivateRoute from '../components/PrivateRoute'
import Dashboard from '../components/app/Dashboard'
import Login from '../components/app/Login'
import Register from '../components/app/Register'
import ForgotPassword from '../components/app/ForgotPassword'
import Schedule from '../components/app/Schedule'
import Prescription from '../components/app/Prescription'
import Doctors from '../components/app/DoctorsCorner'
import Pharmacists from '../components/app/PharmacyCorner'
import AdminCorner from '../components/app/AdminCorner'
import Registration from '../components/app/Registration'
import MedicineOnly from '../components/app/MedicineOnly'
import CreatePayment from '../components/app/CreatePayment'
import Patients from '../components/app/Patients'
import Appointments from '../components/app/Appointments'
import Requests from '../components/app/Requests'
import Cases from '../components/app/Cases'
import Invoice from '../components/app/Invoice'
import PrescriptionsList from '../components/app/PrescriptionsList'
import Details from '../components/app/Details'
import Payment from '../components/app/Payment'
import ProtectedRoute from '../components/ProtectedRoute'
import Reference from '../components/app/Reference'
import WorkflowUpdate from '../components/app/workflowUpdate'
import Status from '../components/app/Status'
import SpecialPrivileges from '../components/app/SpecialPrivileges'
import Followups from '../components/app/Followups'
import MedicineOnlyRequests from '../components/app/MedicineOnlyRequests'
import Earnings from '../components/app/Earnings'
import PatientDocument from '../components/app/PatientDocument'
import DownloadDoc from '../components/app/DownloadDoc'
import Responses from '../components/app/Responses'

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/special" component={SpecialPrivileges} />
        <PrivateRoute path="/schedule" component={Schedule} />
        <PrivateRoute path="/prescription" component={Prescription} />
        <PrivateRoute path="/doctors" component={Doctors} />
        <PrivateRoute path="/pharmacy" component={Pharmacists} />
        <PrivateRoute path="/admin" component={AdminCorner} />
        <PrivateRoute path="/registration" component={Registration} />
        <PrivateRoute path="/workflow" component={WorkflowUpdate} />
        <PrivateRoute path="/earnings" component={Earnings} />
        <PrivateRoute path="/status" component={Status} />
        <PrivateRoute path="/medsrequest" component={MedicineOnly} />
        <PrivateRoute path="/payment" component={CreatePayment} />
        <PrivateRoute path="/document" component={PatientDocument} />
        <PrivateRoute path="/patients" component={Patients} />
        <PrivateRoute path="/appointments" component={Appointments} />
        <PrivateRoute path="/requests" component={Requests} />
        <PrivateRoute path="/medicineonly" component={MedicineOnlyRequests} />
        <PrivateRoute path="/cases" component={Cases} />
        <PrivateRoute path="/followups" component={Followups} />
        <PrivateRoute path="/invoice" component={Invoice} />
        <PrivateRoute path="/reference" component={Reference} />
        <PrivateRoute path="/responses" component={Responses} />
        <PrivateRoute path="/prescriptions" component={PrescriptionsList} />
        <PrivateRoute path="/details" component={Details} />
        <ProtectedRoute path="/payinvoice/:uid/:caseid" component={Payment} />
        <ProtectedRoute
          path="/downloaddoc/:uid/:patientid"
          component={DownloadDoc}
        />
        <Login path="/login" />
        <Register path="/regis" />
        <ForgotPassword path="/forgotpassword" />
      </Router>
    </Layout>
  )
}
export default App
