import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import BannerGeneric from '../BannerGeneric'
import AppointmentsView from '../Appointments'

const Appointments: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <BannerGeneric
        title="Appointments"
        description="This is where all appointments are listed"
      >
        {
          <ul className="actions">
            <li>
              <button onClick={() => history.back()} className="button special">
                Back
              </button>
            </li>
            <li>
              <Link to="/app/dashboard" className="button special">
                Dashboard
              </Link>
            </li>
          </ul>
        }
      </BannerGeneric>
      <AppointmentsView />
    </>
  )
}

export default Appointments
