import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { bloomBfetch, getUsername } from '../utils'
import { BB_QUICK_REFERENCES_GOOGLESHEET_ID } from '../utils/constants'
import { useGoogleSheet } from './hooks'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

export default function OutlinedCard({ text }: { text?: string }) {
  // const [loggedInUsername] = useLoggedInUser()

  const [googleData] = useGoogleSheet(BB_QUICK_REFERENCES_GOOGLESHEET_ID)
  console.log('CRYPS', googleData)
  const [filteredGoogleSheetData, setFilteredGoogleSheetData] = useState([])
  console.log('TYPEF', filteredGoogleSheetData)

  useEffect(() => {
    const fetchDetails = async () => {
      const typeformData = await bloomBfetch(
        'https://cors-anywhere.herokuapp.com/https://api.typeform.com/forms/f4TgfDA3/responses',
        {
          Authorization: `Bearer AXKYTFtP21UkUGNzJ1axztyTEm3xnJ6or5KMQd83s42j`,
          'Access-Control-Allow-Origin': '*',
          'X-Requested-With': 'XMLHttpRequest',
        }
      )
      let typeforms: any[] = []
      typeformData.items.map(item => {
        typeforms.push({ ...item.answers, submitted_at: item['submitted_at'] })
      })
      setFilteredGoogleSheetData(typeforms)

      console.log('TYPEF 1', typeformData)
    }
    fetchDetails()
  }, [])

  const [googleSheetData, setGoogleSheetData] = useState([])
  useEffect(() => {
    if (googleData.length) {
      const filtered = googleData.filter(
        item =>
          (item['Title'] && item['Title'].toLowerCase().search(text) !== -1) ||
          (item['Description'] && item['Description'].search(text) !== -1)
      )
      setGoogleSheetData(filtered)
    }
  }, [googleData, text])

  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>

  return (
    <>
      <section>
        <div className="box alt">
          <div className="grid-wrapper">
            {googleSheetData.map((newRowData: any) => {
              // console.log('STABID', newRowData)
              // const imgUrl = getUrlParam(
              //   newRowData['Clinic Prescription'],
              //   'id'
              // )
              return (
                <div className="col-4" key={newRowData['submitted_at']}>
                  <Card
                    style={{
                      margin: '10px 0',
                    }}
                  >
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        Updated On : {newRowData['Timestamp']}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ color: '#000' }}
                      >
                        {newRowData['Title']}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {getUsername(newRowData["Pharmacist's bloomB ID"])}'s
                        References
                      </Typography>
                      {/* <Typography variant="body2" component="p">
                        Updated On : {newRowData['Timestamp']}
                      </Typography>
                      <br /> */}
                      <Typography
                        variant="body2"
                        component="p"
                        style={{ height: '150px' }}
                      >
                        {newRowData['Description']}
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
                      <ShowModal
                        buttonLabel="View"
                        title={`${getUsername(
                          newRowData["Pharmacist's bloomB ID"]
                        )}'s Prescription`}
                      >
                        <img
                          src={`https://drive.google.com/uc?export=view&id=${imgUrl}`}
                          width="100%"
                        />
                      </ShowModal>

                      {isApprovedRole([BB_ADMIN]) && (
                        <RestrictedButton
                          text="Invoice"
                          roles={[BB_ADMIN]}
                          linkTo={`/app/payment`}
                          target={true}
                          state={{
                            data: newRowData,
                          }}
                        />
                      )}
                    </CardActions> */}
                  </Card>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}
